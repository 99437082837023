// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialKosten, MaterialKostenUpdate } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateMaterialKosten(): (
  materialId: string,
  requestBody: MaterialKostenUpdate
) => Promise<MaterialKosten> {
  const getAPI = useGetApi();
  return useCallback(
    async (materialId, requestBody) =>
      (await getAPI()).material.updateMaterialKosten({
        materialId,
        requestBody,
      }),
    [getAPI]
  );
}

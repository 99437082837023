import {
  Box,
  Button,
  capitalize,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { PersonalTyp, TaetigkeitsberichtInternInfo } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";

export type TaetigkeitsberichtFilterFreigabe = {
  search?: string;
  filtered?: string;
};

export type Taetigkeitsbericht = {
  id: string;
  baustelleId: string;
  datum: string;
  taetigkeitsberichtTyp: PersonalTyp;
  personaleintrag: Personaleintrag;
};

export type Personaleintrag = {
  id: string;
  mitarbeiterId?: string;
  baustelleId: string;
  taetigkeitsberichtId: string;
  datum: string;
  arbeitszeitNetto?: number;
  lohnkosten?: number;
  stundensatz?: number;
  bemerkung?: string;
};

export type Props = Omit<
  DataRequest<TaetigkeitsberichtFilterFreigabe>,
  "filter"
> &
  Partial<Pick<DataRequest<TaetigkeitsberichtFilterFreigabe>, "filter">>;

export default function TaetigkeitsberichtDataTableFreigabe({
  ...input
}: Props) {
  const request = useDataRequest<TaetigkeitsberichtFilterFreigabe>({
    filter: {},
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <TaetigkeitsberichtResultsFreigabe {...request} />
    </Stack>
  );
}

function FilterComp({
  ...request
}: DataRequestState<TaetigkeitsberichtFilterFreigabe>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
        </>
      )}
    </Box>
  );
}

function TaetigkeitsberichtResultsFreigabe({
  ...request
}: DataRequestState<TaetigkeitsberichtFilterFreigabe>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<TaetigkeitsberichtFilterFreigabe>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();

  const ladeTaetigkeitsberichte: DataLoader<
    TaetigkeitsberichtFilterFreigabe,
    TaetigkeitsberichtInternInfo
  > = useCallback(
    async (params) =>
      (await getApi()).taetigkeitsberichte.sucheInterneTaetigkeitsberichte({
        freigegeben: false,
        ...params,
      }),
    [getApi]
  );

  const [{ search, filtered }, setField] = useDebouncedFilter(request);
  const [filteredState, setFilteredState] = useState("");

  useEffect(() => {
    if (filteredState) {
      setField("filtered", filteredState);
    }
  }, [filteredState]);
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["taetigkeitsberichte", "INTERN"]} //TODO: Richtigen QueryKey verwenden
      loadData={ladeTaetigkeitsberichte}
      onRowClick={(row) => {
        navigate("/berichtfreigabe/" + row.id);
      }}
      name={"taetigkeitsberichte"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<TaetigkeitsberichtInternInfo>> {
  const { t, capitalize } = useT("taetigkeitsbericht");

  return useMemo(
    () => [
      {
        field: "mitarbeiter",
        headerName: capitalize(t("activity-report-user")),
        flex: 1,
      },
      {
        field: "baustellenBezeichnung",
        headerName: capitalize(t("activity-report-construction")),
        flex: 1,
      },
      {
        field: "datum",
        headerName: capitalize(t("activity-report-date")),
        flex: 1,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "arbeitszeitNetto",
        headerName: capitalize(t("activity-report-time-net")),
        flex: 1,
        type: "number",
      },
      {
        field: "bemerkung",
        headerName: capitalize(t("activity-report-remark")),
        flex: 1,
      },
      {
        field: "bearbeitetUm",
        headerName: capitalize(t("processing-time")),
        flex: 1.5,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            const formattedTime = date.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            return `${formattedDate} ${formattedTime}`;
          }
          return "";
        },
      },
    ],
    [t, capitalize]
  );
}

import { Form, Formik, FormikHelpers, useField, FormikProps } from "formik";
import Layout from "../../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import FahrzeugFieldsContent from "../../../components/fuhrpark/fahrzeug/FahrzeugFieldsContent";
import { useCreateFahrzeug } from "../../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  checkFahrzeugMessage,
  FahrzeugDaten,
  FahrzeugGruppe,
  HerkunftTyp,
} from "../../../api";
import { apiUrl } from "../../../config";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import Guard from "../../../components/Guard";
import { useGetAccessToken } from "../../../client";

export default function ErstelleFahrzeug() {
  const { t } = useTranslation("fuhrpark");

  const { mutateAsync } = useSaveFahrzeug();
  const getAccessToken = useGetAccessToken();
  let schema = yup.object().shape({
    gruppe: yup.string().required(capitalize(t("vehicle-required-group"))),
    herkunft: yup.string().required(capitalize(t("vehicle-required-origin"))),
    fabrikant: yup.string().when("herkunft", {
      is: "EIGEN_GERAET",
      then: (schema) => schema.required(capitalize(t("vehicle-required-make"))),
    }),
    fahrzeugTyp: yup.string().when("herkunft", {
      is: "EIGEN_GERAET",
      then: (schema) => schema.required(capitalize(t("vehicle-required-type"))),
    }),
    laufendeNummer: yup.string(),
    kennzeichen: yup
      .string()
      .test(
        "is-unique",
        capitalize(t("vehicle-mark-already-exists")),
        async (value) => {
          const response = await fetch(`${apiUrl}/fahrzeuge-check`, {
            method: "POST",
            headers: {
              authorization: `Bearer ${await getAccessToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ kennzeichen: value }),
          });

          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const fahrzeuge: checkFahrzeugMessage = await response.json();
          return fahrzeuge.check === undefined || fahrzeuge.check;
        }
      )
      .required(capitalize(t("vehicle-required-mark"))),
    angeschafft: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    breite: yup.number().min(0).nullable(),
    tiefe: yup.number().min(0).nullable(),
    hoehe: yup.number().min(0).nullable(),
    gesamtMasse: yup.number().min(0).nullable(),
    lautstaerke: yup.number().min(0).nullable(),
    kostenStundeAktuell: yup
      .number()
      .min(0)
      .required(capitalize(t("vehicle-required-cost-hour"))),
    ladeMenge: yup.number().min(0).nullable(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        gruppe: FahrzeugGruppe;
        herkunft: HerkunftTyp;
        fabrikant: string;
        fahrzeugTyp: string;
        laufendeNummer: string;
        kennzeichen: string;
        angeschafft: string;
        breite: number;
        tiefe: number;
        hoehe: number;
        gesamtMasse: number;
        lautstaerke: number;
        kostenStundeAktuell: number;
        ladeMenge: number;
      },
      formikHelpers: FormikHelpers<{
        gruppe: FahrzeugGruppe;
        herkunft: HerkunftTyp;
        fabrikant: string;
        fahrzeugTyp: string;
        laufendeNummer: string;
        kennzeichen: string;
        angeschafft: string;
        breite: number;
        tiefe: number;
        hoehe: number;
        gesamtMasse: number;
        lautstaerke: number;
        kostenStundeAktuell: number;
        ladeMenge: number;
      }>
    ) => {
      const {
        gruppe,
        herkunft,
        fabrikant,
        fahrzeugTyp,
        laufendeNummer,
        kennzeichen,
        angeschafft,
        breite,
        tiefe,
        hoehe,
        gesamtMasse,
        lautstaerke,
        kostenStundeAktuell,
        ladeMenge,
      } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      if (angeschafft !== "") {
        const fahrzeug = await mutateAsync({
          gruppe: gruppe,
          herkunft: herkunft,
          fabrikant: fabrikant,
          fahrzeugTyp: fahrzeugTyp,
          laufendeNummer: laufendeNummer,
          kennzeichen: kennzeichen,
          angeschafft: angeschafft + "T00:00:00.000Z",
          breite: breite,
          tiefe: tiefe,
          hoehe: hoehe,
          gesamtMasse: gesamtMasse,
          lautstaerke: lautstaerke,
          kostenStundeAktuell: kostenStundeAktuell,
          ladeMenge: ladeMenge,
        });
        navigate(`/fahrzeuge`);
      } else {
        const fahrzeug = await mutateAsync({
          gruppe: gruppe,
          herkunft: herkunft,
          fabrikant: fabrikant,
          fahrzeugTyp: fahrzeugTyp,
          laufendeNummer: laufendeNummer,
          kennzeichen: kennzeichen,
          breite: breite,
          tiefe: tiefe,
          hoehe: hoehe,
          gesamtMasse: gesamtMasse,
          lautstaerke: lautstaerke,
          kostenStundeAktuell: kostenStundeAktuell,
          ladeMenge: ladeMenge,
        });
        navigate(`/fahrzeuge`);
      }

      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={t("vehicle-creation-form")} back="/fahrzeuge">
      <Guard
        permission={"fuhrpark:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              gruppe: "BAGGER",
              herkunft: "EIGEN_GERAET",
              fabrikant: "",
              fahrzeugTyp: "",
              laufendeNummer: "",
              kennzeichen: "",
              angeschafft: "",
              breite: 0,
              tiefe: 0,
              hoehe: 0,
              gesamtMasse: 0,
              lautstaerke: 0,
              kostenStundeAktuell: 0.0,
              ladeMenge: 0,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">{t("new-vehicle")}</Typography>
                <DialogContent>
                  <Form id="neues-fahrzeug">
                    <FahrzeugFieldsContent isNew={true} />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="neues-fahrzeug"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

function useSaveFahrzeug() {
  const createFahrzeug = useCreateFahrzeug();
  const queryClient = useQueryClient();

  return useMutation(
    (input: FahrzeugDaten) => {
      return createFahrzeug(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["fahrzeuge"]);
      },
    }
  );
}

import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useBenutzer } from "../../client/queries";
import { useUpdateBenutzer } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { BenutzerDaten } from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import BenutzerFieldsContent from "../../components/benutzer/BenutzerFieldsContent";
import DeleteBenutzerButton from "../../components/benutzer/DeleteBenutzerButton";
import Guard from "../../components/Guard";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";

export default function DetailsBenutzer() {
  const { id: benutzerId = "" } = useParams<{
    id: string;
  }>();
  const { t } = useTranslation("customer");
  const benutzer = useBenutzer(benutzerId);
  const { mutateAsync } = useSaveBenutzer(benutzerId);
  let schema = yup.object().shape({
    name: yup.string().required(),
    plz: yup.string(),
    strasse: yup.string(),
    ort: yup.string(),
    telefon: yup.string(),
    email: yup.string().required(),
    personalNummer: yup.number().integer().positive().nullable(),
    vorname: yup.string(),
    geburtsDatum: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    eintrittsDatum: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    wochenArbeitszeit: yup
      .string()
      .test("is-decimal", "invalid wochenArbeitszeit", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    stundenLohn: yup
      .string()
      .test("is-decimal", "invalid stundenLohn", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    pauschalVerguetung: yup
      .string()
      .test("is-decimal", "invalid pauschalVerguetung", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    berechnungsSatz: yup
      .string()
      .test("is-decimal", "invalid berechnungsSatz", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    anrede: yup.string(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        personalNummer: number | undefined;
        vorname: string;
        geburtsDatum: string;
        eintrittsDatum: string;
        wochenArbeitszeit: string;
        stundenLohn: string;
        pauschalVerguetung: string;
        anrede: string;
        berechnungsSatz: string;
      },
      formikHelpers: FormikHelpers<{
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        personalNummer: number | undefined;
        vorname: string;
        geburtsDatum: string;
        eintrittsDatum: string;
        wochenArbeitszeit: string;
        stundenLohn: string;
        pauschalVerguetung: string;
        anrede: string;
        berechnungsSatz: string;
      }>
    ) => {
      const geburtsDatum =
        values.geburtsDatum === ""
          ? undefined
          : values.geburtsDatum + "T00:00:00Z";
      const eintrittsDatum =
        values.eintrittsDatum === ""
          ? undefined
          : values.eintrittsDatum + "T00:00:00Z";
      let wochenArbeitszeit = values.wochenArbeitszeit
        ? parseFloat(values.wochenArbeitszeit.replace(",", "."))
        : 0;
      let stundenlohn = values.stundenLohn
        ? parseFloat(values.stundenLohn.replace(",", "."))
        : 0;
      let pauschalVerguetung = values.pauschalVerguetung
        ? parseFloat(values.pauschalVerguetung.replace(",", "."))
        : 0;
      let berechnungssatz = values.berechnungsSatz
        ? parseFloat(values.berechnungsSatz.replace(",", "."))
        : 0;
      const updatedBenutzer = await mutateAsync({
        name: values.name,
        email: values.email,
        personalNummer: values.personalNummer,
        vorname: values.vorname,
        strasse: values.strasse,
        plz: values.plz,
        ort: values.ort,
        telefon: values.telefon,
        geburtsDatum: geburtsDatum,
        eintrittsDatum: eintrittsDatum,
        wochenArbeitszeit: wochenArbeitszeit,
        stundenLohn: stundenlohn,
        pauschalVerguetung: pauschalVerguetung,
        anrede: values.anrede,
        berechnungsSatz: berechnungssatz,
      });
      const { setSubmitting, setStatus } = formikHelpers;
      navigate(`/benutzer`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value.toFixed(2).replace(".", ",");
  };
  return (
    <Layout title={"Benutzer"} back="/benutzer">
      <Guard
        permission={"benutzer:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          <Guard permission={"benutzer:delete"}>
            <DeleteBenutzerButton id={benutzerId} />
          </Guard>
          <Formik
            enableReinitialize
            initialValues={{
              anrede: benutzer.anrede ?? "",
              personalNummer: benutzer.personalNummer ?? undefined,
              name: benutzer.name ?? "",
              plz: benutzer.plz ?? "",
              strasse: benutzer.strasse ?? "",
              ort: benutzer.ort ?? "",
              telefon: benutzer.telefon ?? "",
              email: benutzer.email ?? "",
              vorname: benutzer.vorname ?? "",
              geburtsDatum: benutzer.geburtsDatum?.split("T")[0] ?? "",
              eintrittsDatum: benutzer.eintrittsDatum?.split("T")[0] ?? "",
              wochenArbeitszeit: formatNumber(benutzer.wochenArbeitszeit) ?? "",
              stundenLohn: formatNumber(benutzer.stundenLohn) ?? "",
              pauschalVerguetung:
                formatNumber(benutzer.pauschalVerguetung) ?? "",
              berechnungsSatz: formatNumber(benutzer.berechnungsSatz) ?? "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">
                  {benutzer.geloeschtAm === undefined ? (
                    "Benutzer"
                  ) : (
                    <>
                      <>Benutzer</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                <DialogContent>
                  <Form id="details-customer">
                    <BenutzerFieldsContent />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="details-customer"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveBenutzer(id: string) {
  const updateBenutzer = useUpdateBenutzer();
  const queryClient = useQueryClient();

  return useMutation(
    (input: BenutzerDaten) => {
      return updateBenutzer(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["benutzer", id]);
      },
    }
  );
}

import { Form, Formik, FormikHelpers, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
  IconButton,
  Grid,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Switch,
  Dialog,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import AbladestelleFieldsContent from "../../components/abladestellen/AbladestelleFieldsContent";
import {
  useGetAbladestelle,
  useUpdateAbladestelle,
  useCreateMaterial,
  useDeleteMaterial,
  useUpdateMaterial,
  useFetchMaterialsByAbladestelleId,
  useGetAbfallart,
} from "../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import DeleteAbladestelleButton from "../../components/abladestellen/AbladestellenDeleteButton";
import { Abladestelle } from "../../api";
import AbfallartenSelect from "../../components/abfallarten/AbfallartenSelect";
import HelpIcon from "@mui/icons-material/Help";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import AbfallartenEAKSelect from "../../components/abfallarten/AbfallartenEAKSelect";
import MaterialKostenGrid from "../../components/material/materialkosten/MaterialkostenGrid";

export interface Material {
  id?: string;
  name: string;
  archiviert: boolean;
  abfallart: any;
  abfallartKey?: string;
  abladestelleId?: string;
  kostenProTonne?: string;
  kostenProKubikmeter?: string;
}

interface MaterialUpdated {
  id?: string;
  name: string;
  archiviert: boolean;
  abfallartId: any;
  abfallartKey?: string;
  abladestelleId?: string;
  kostenProTonne?: number;
  kostenProKubikmeter?: number;
}

interface Abfallart {
  id: string;
  name: string;
}

interface ExtendedAbladestelleDaten {
  kurzname: string;
  firma: string;
  zusatz: string;
  ort: string;
  isDeponie: boolean;
  materials: Material[];
}

const validationSchema = Yup.object().shape({
  kurzname: Yup.string().required("Kurzname ist erforderlich"),
  firma: Yup.string(),
  ort: Yup.string(),
  isDeponie: Yup.boolean(),
  materials: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name ist erforderlich"),
        archiviert: Yup.boolean(),
        abfallart: Yup.object().required("Abfallart ist erforderlich"),
        kostenProTonne: Yup.string(),
        kostenProKubikmeter: Yup.string(),
      })
    )
    .min(0, ""),
});

export default function EditAbladestelle() {
  const { id: abladestelleId = "" } = useParams<{ id: string }>();
  const { t } = useTranslation("abladestelle");
  const getAbladestelle = useGetAbladestelle(); // Get the function to fetch Abladestelle
  const updateAbladestelle = useUpdateAbladestelle();
  const createMaterial = useCreateMaterial();
  const deleteMaterial = useDeleteMaterial();
  const updateMaterial = useUpdateMaterial();
  const ladeAbfallart = useGetAbfallart();
  const fetchMaterialsByAbladestelleId = useFetchMaterialsByAbladestelleId();
  const navigate = useNavigate();
  const [materials, setMaterials] = useState<MaterialUpdated[]>([]);
  const [abladestelle, setAbladestelle] = useState<Abladestelle | null>(null);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value.toFixed(2).replace(".", ",");
  };

  useEffect(() => {
    const fetchMaterials = async () => {
      if (abladestelleId) {
        const materialsData = await fetchMaterialsByAbladestelleId(
          abladestelleId
        );
        setMaterials(materialsData.items);
      }
    };
    fetchMaterials();
  }, [abladestelleId, fetchMaterialsByAbladestelleId]);

  useEffect(() => {
    const fetchAbladestelle = async () => {
      if (abladestelleId) {
        const data = await getAbladestelle(abladestelleId);
        setAbladestelle(data);
      }
    };
    fetchAbladestelle();
  }, [abladestelleId, getAbladestelle]);

  const onSubmit = useCallback(
    async (
      values: ExtendedAbladestelleDaten,
      formikHelpers: FormikHelpers<ExtendedAbladestelleDaten>
    ) => {
      const { setSubmitting, setStatus, resetForm } = formikHelpers;
      try {
        // Update Abladestelle first
        await updateAbladestelle(abladestelleId, {
          kurzname: values.kurzname,
          firma: values.firma,
          zusatz: values.zusatz,
          ort: values.ort,
          isDeponie: values.isDeponie,
        });

        // Create or update each material
        const materialPromises = values.materials.map((material) => {
          let kostenProTonne;
          let kostenProKubikmeter;
          if (material.kostenProTonne !== undefined) {
            kostenProTonne = parseFloat(
              material.kostenProTonne.toString().replace(",", ".")
            );
          }
          if (material.kostenProKubikmeter !== undefined) {
            kostenProKubikmeter = parseFloat(
              material.kostenProKubikmeter.toString().replace(",", ".")
            );
          }
          const abfallartId = (material.abfallart as Abfallart)?.id ?? null;
          const materialData = {
            name: material.name,
            abladestelleId: abladestelleId, // Ensure this is always a string
            abfallartId: abfallartId,
            archiviert: material.archiviert,
            kostenProTonne: kostenProTonne,
            kostenProKubikmeter: kostenProKubikmeter,
          };
          if (material.id) {
            // Update existing material
            return updateMaterial(material.id, materialData);
          } else {
            // Create new material
            return createMaterial(materialData);
          }
        });

        await Promise.all(materialPromises);

        navigate(`/abladestellen/`, { replace: true });
        setStatus(undefined);
        const materialsData = await fetchMaterialsByAbladestelleId(
          abladestelleId
        );
        setMaterials(materialsData.items);
        const data = await getAbladestelle(abladestelleId);
        setAbladestelle(data);
        resetForm({
          values: {
            ...values,
            materials: materials.map((material) => ({
              id: material.id,
              name: material.name,
              archiviert: material.archiviert ?? false,
              abfallart: material.abfallartId,
              abfallartKey: material.abfallartKey,
              kostenProTonne: formatNumber(material.kostenProTonne),
              kostenProKubikmeter: formatNumber(material.kostenProKubikmeter),
            })),
          },
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      updateAbladestelle,
      createMaterial,
      updateMaterial,
      abladestelleId,
    ]
  );

  if (!abladestelle) {
    return <div>Loading...</div>;
  }

  const handleDialogOpen = (index: any) => {
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  return (
    <Layout
      title={t("details-landfill-and-unloading-point")}
      back="/abladestellen"
    >
      <Guard
        permission={"abladestelle:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Guard permission={"abladestelle:delete"}>
            <DeleteAbladestelleButton
              id={abladestelleId}
            ></DeleteAbladestelleButton>
          </Guard>
          <Formik<ExtendedAbladestelleDaten>
            initialValues={{
              kurzname: abladestelle.kurzname ?? "",
              firma: abladestelle.firma ?? "",
              zusatz: abladestelle.zusatz ?? "",
              ort: abladestelle.ort ?? "",
              isDeponie: abladestelle.isDeponie ?? false,
              materials: materials.map((material) => ({
                id: material.id,
                name: material.name,
                archiviert: material.archiviert ?? false,
                abfallart: material.abfallartId,
                abfallartKey: material.abfallartKey,
                kostenProTonne: formatNumber(material.kostenProTonne),
                kostenProKubikmeter: formatNumber(material.kostenProKubikmeter),
              })),
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              isValidating,
              isValid,
              dirty,
              values,
              status,
              setFieldValue,
            }) => (
              <>
                <Typography variant="h5">
                  {abladestelle.geloeschtAm === undefined ? (
                    t("landfill-and-unloading-points")
                  ) : (
                    <>
                      <>Abladestelle</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                {status && (
                  <Typography color="error" variant="body1">
                    {status}
                  </Typography>
                )}
                <DialogContent>
                  <Form id="details-abladestelle">
                    <AbladestelleFieldsContent />
                    <FieldArray name="materials">
                      {({ push, remove }) => (
                        <>
                          {values.materials.map((material: Material, index) => (
                            <Box key={index} sx={{ marginTop: 2 }}>
                              <Dialog
                                title={t("new-attachment-part")}
                                open={openDialogIndex === index}
                                onClose={handleDialogClose}
                                maxWidth={"lg"}
                                fullWidth={true}
                              >
                                <DialogContent>
                                  {material.id ? (
                                    <MaterialKostenGrid
                                      materialId={material.id}
                                      abladestellenId={abladestelleId}
                                      material={material}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </DialogContent>
                              </Dialog>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Field
                                    name={`materials.${index}.name`}
                                    as={TextField}
                                    label={t("Material Name")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Field
                                    name={`materials.${index}.abfallart`}
                                    component={AbfallartenEAKSelect}
                                    label="Abfallart"
                                    fullWidth
                                    required
                                    keyField={`materials.${index}.abfallartKey`}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Stack>
                                    <Typography color={"textSecondary"}>
                                      EAK-Schlüssel
                                    </Typography>
                                    <Box>
                                      {values.materials[index].abfallartKey}
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid item xs={1.5}>
                                  <Field
                                    name={`materials.${index}.kostenProTonne`}
                                    as={TextField}
                                    label={t("price-per-tons")}
                                    inputProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid item xs={1.5}>
                                  <Field
                                    name={`materials.${index}.kostenProKubikmeter`}
                                    as={TextField}
                                    label={t("price-per-cubic")}
                                    inputProps={{ shrink: true }}
                                  />
                                </Grid>
                                {material.id ? (
                                  <IconButton
                                    onClick={() => {
                                      if (material.id) {
                                        handleDialogOpen(index);
                                      }
                                    }}
                                  >
                                    <HelpIcon />
                                  </IconButton>
                                ) : (
                                  <Box width={40}></Box>
                                )}
                                <Grid item xs={1}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`materials.${index}.archiviert`}
                                        as={Switch}
                                        color="primary"
                                        type="checkbox"
                                      />
                                    }
                                    label={t("Archiviert")}
                                  />
                                </Grid>

                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => {
                                      const materialId =
                                        values.materials[index].id;
                                      if (materialId) {
                                        deleteMaterial(materialId).then(() => {
                                          remove(index);
                                        });
                                      } else {
                                        remove(index);
                                      }
                                    }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                          <Box sx={{ marginTop: 2 }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                push({
                                  name: "",
                                  archiviert: false,
                                  abfallart: "",
                                })
                              }
                            >
                              {t("Material hinzufügen")}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="details-abladestelle"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

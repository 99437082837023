import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { BaustellenInfo } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";

export type BaustellenFilter = {
  search?: string;
  kundenId?: string;
  archivierte?: string;
  filtered?: string;
};

export type Props = Omit<DataRequest<BaustellenFilter>, "filter"> &
  Partial<Pick<DataRequest<BaustellenFilter>, "filter">>;

export default function BaustellenDataTable({ ...input }: Props) {
  const request = useDataRequest<BaustellenFilter>({ filter: {}, ...input });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <BaustellenResults
        request={request}
        setFilteredState={setFilteredState}
      />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<BaustellenFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search, filtered, archivierte }, setField] =
    useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>Archiv</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={archivierte ?? "nur aktive"}
              onChange={(e) =>
                setField(
                  "archivierte",
                  e.target.value !== "nur aktive"
                    ? e.target.value
                    : "nur aktive"
                )
              }
            >
              <MenuItem key="nur aktive" value="nur aktive">
                Nur aktive
              </MenuItem>
              <MenuItem key="alle" value="alle">
                Alle
              </MenuItem>
              <MenuItem key="nur archivierte" value="nur archivierte">
                Nur archivierte
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-baustelle");
            }}
          >
            Baustelle anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>Archiv</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={archivierte ?? "nur aktive"}
              onChange={(e) =>
                setField(
                  "archivierte",
                  e.target.value !== "nur aktive"
                    ? e.target.value
                    : "nur aktive"
                )
              }
            >
              <MenuItem key="nur aktive" value="nur aktive">
                Nur aktive
              </MenuItem>
              <MenuItem key="alle" value="alle">
                Alle
              </MenuItem>
              <MenuItem key="nur archivierte" value="nur archivierte">
                Nur archivierte
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-baustelle");
            }}
          >
            Baustelle anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function BaustellenResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<BaustellenFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}

function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<BaustellenFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadBaustellen: DataLoader<BaustellenFilter, BaustellenInfo> =
    useCallback(
      async (params) =>
        (await getApi()).baustellen.sucheBaustellen({ ...params }),
      [getApi]
    );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["baustellen"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadBaustellen}
      onRowClick={(row) => {
        navigate("/baustellen/" + row.id);
      }}
      name={"baustellen"}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<BaustellenInfo>> {
  const { t, capitalize } = useT("customer");

  return useMemo(
    () => [
      {
        field: "nummer",
        headerName: t("number"),
        type: "number",
        renderCell: (params) => {
          const value = params.value as number | null | undefined;
          return value !== null && value !== undefined ? `${value}` : "";
        },
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
      },
      {
        field: "kundenName",
        headerName: t("customer-name"),
        flex: 1,
      },
      {
        field: "strasse",
        headerName: t("street"),
        flex: 1,
      },
      {
        field: "plz",
        headerName: t("plz"),
        flex: 1,
      },
      {
        field: "ort",
        headerName: t("city"),
        flex: 1,
      },
      {
        field: "auftragsSumme",
        headerName: t("order-total"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) =>
          `${(value as number)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`,
      },
      {
        field: "saldo",
        headerName: t("balance"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) =>
          `${(value as number)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`,
      },
    ],
    [t]
  );
}

import {
  Box,
  Button,
  capitalize,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { PersonalTyp, TaetigkeitsberichtExternInfo } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";

export type TaetigkeitsberichtFilter = {
  search?: string;
  filtered?: string;
};

export type Taetigkeitsbericht = {
  id: string;
  baustelleId: string;
  datum: string;
  taetigkeitsberichtTyp: PersonalTyp;
};

export type Props = Omit<DataRequest<TaetigkeitsberichtFilter>, "filter"> &
  Partial<Pick<DataRequest<TaetigkeitsberichtFilter>, "filter">>;

export default function TaetigkeitsberichtDataTableExtern({ ...input }: Props) {
  const request = useDataRequest<TaetigkeitsberichtFilter>({
    filter: {},
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <TaetigkeitsberichtResults {...request} />
    </Stack>
  );
}

function FilterComp({
  ...request
}: DataRequestState<TaetigkeitsberichtFilter>) {
  const { t } = useT("taetigkeitsbericht");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neue-fremdleistung");
            }}
          >
            {capitalize(t("create-activity-report-external"))}
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neue-fremdleistung");
            }}
          >
            {capitalize(t("create-activity-report-external"))}
          </Button>
        </>
      )}
    </Box>
  );
}

function TaetigkeitsberichtResults({
  ...request
}: DataRequestState<TaetigkeitsberichtFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<TaetigkeitsberichtFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();

  const ladeTaetigkeitsberichte: DataLoader<
    TaetigkeitsberichtFilter,
    TaetigkeitsberichtExternInfo
  > = useCallback(
    async (params) =>
      (await getApi()).taetigkeitsberichte.sucheExterneTaetigkeitsberichte({
        ...params,
      }),
    [getApi]
  );
  const [{ search, filtered }, setField] = useDebouncedFilter(request);
  const [filteredState, setFilteredState] = useState("");
  useEffect(() => {
    if (filteredState) {
      setField("filtered", filteredState);
    }
  }, [filteredState]);
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["taetigkeitsberichte", "EXTERN"]} //TODO: Richtigen QueryKey verwenden
      loadData={ladeTaetigkeitsberichte}
      onRowClick={(row) => {
        navigate("/fremdleistungen/" + row.id);
      }}
      name={"taetigkeitsberichte-extern"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<TaetigkeitsberichtExternInfo>> {
  const { t, capitalize } = useT("taetigkeitsbericht");

  return useMemo(
    () => [
      {
        field: "lieferant",
        headerName: capitalize(t("activity-report-supplier")),
        flex: 1,
      },
      {
        field: "baustellenBezeichnung",
        headerName: capitalize(t("activity-report-construction")),
        flex: 1,
      },
      {
        field: "datum",
        headerName: capitalize(t("activity-report-date")),
        flex: 1,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          } else {
            return "";
          }
        },
      },
      {
        field: "bearbeitetUm",
        headerName: capitalize(t("processing-time")),
        flex: 1.5,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            const formattedTime = date.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            return `${formattedDate} ${formattedTime}`;
          }
          return "";
        },
      },
    ],
    [t, capitalize]
  );
}

// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Identifiable } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetMaterialCostId(): (
  materialId: string
) => Promise<Identifiable> {
  const getAPI = useGetApi();
  return useCallback(
    async (materialId) =>
      (await getAPI()).material.getMaterialCostId({ materialId }),
    [getAPI]
  );
}

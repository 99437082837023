import { Box, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import BenutzerSelect from "../benutzer/BenutzerSelect";
import React from "react";

export default function AbwesenheitenFieldsContent({
  isVerwaltung,
}: {
  isVerwaltung: boolean;
}) {
  const { setFieldValue } = useFormikContext();

  return (
    <Stack direction="column">
      {isVerwaltung ? (
        <Box
          display={"grid"}
          sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
        >
          <Field
            component={BenutzerSelect}
            label={"Mitarbeiter *"}
            name="mitarbeiter"
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Start *"}
          name="start"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <Field
          component={TextField}
          label={"Ende *"}
          name="ende"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field component={TextField} label={"Bemerkung"} name="bemerkung" />
        <Field
          name="type"
          component={Select}
          label={"Typ"}
          onChange={async (e: SelectChangeEvent) => {
            const typ = e.target.value;
            if (typ === "Urlaub") {
              await setFieldValue("brzKey", "U");
            }
            if (typ === "Krankmeldung") {
              await setFieldValue("brzKey", "K");
            }
          }}
        >
          <MenuItem key="urlaub" value="Urlaub">
            Urlaub
          </MenuItem>
          <MenuItem key="krankmeldung" value="Krankmeldung">
            Krankheit
          </MenuItem>
          <MenuItem key="sonstiges" value="Sonstiges">
            Sonstige Abwesenheit
          </MenuItem>
        </Field>
        <BrzKeySelect required />
      </Box>
    </Stack>
  );
}

function BrzKeySelect({ required = false }: { required: boolean }) {
  return (
    <Field
      name="brzKey"
      component={Select}
      label={`BRZ-Schlüssel ${required ? "*" : ""}`}
    >
      <MenuItem key="U" value="U">
        U: Urlaub
      </MenuItem>
      <MenuItem key="K" value="K">
        K: Krank
      </MenuItem>
      <MenuItem key="TU" value="TU">
        TU: Tarifurlaub
      </MenuItem>
      <MenuItem key="UU" value="UU">
        UU: unbezahlter Urlaub
      </MenuItem>
      <MenuItem key="FE" value="FE">
        FE: Fehlt entschuldigt
      </MenuItem>
      <MenuItem key="KU" value="KU">
        KU: Krank Unfall
      </MenuItem>
      <MenuItem key="KK" value="KK">
        KK: Krankenkasse Krankengeld
      </MenuItem>
      <MenuItem key="KB" value="KB">
        KB: Krankengeld nach Betriebsunfall
      </MenuItem>
      <MenuItem key="AU" value="AU">
        AU: Austeuerung
      </MenuItem>
      <MenuItem key="EU" value="EU">
        EU: Elternzeit / Urlaub
      </MenuItem>
    </Field>
  );
}

import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Lieferant, LieferantenListe } from "../../api";
import { useGetLieferant, useSucheLieferanten } from "../../client/hooks";

interface LieferantenSelectProps extends FieldProps {
  label: string;
  required?: boolean;
}

function LieferantenSelect({
  label,
  required,
  field,
  form,
  meta,
}: LieferantenSelectProps) {
  const searchLieferanten = useSucheLieferanten();
  const ladeLieferant = useGetLieferant();
  const [optionen, setOptionen] = useState<ReadonlyArray<Lieferant>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeLieferant(field.value)
        .then((lieferant) => {
          form.setFieldValue(field.name, lieferant);
          setInputValue(lieferant);
        })
        .catch((error) => {
          console.error("Error loading lieferant:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: LieferantenListe) => void
        ) =>
          searchLieferanten({
            search: request.input,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then(async (results) => {
              const priorityIds = JSON.parse(
                localStorage.getItem("letzteLieferanten") ?? "[]"
              );

              // Fetch all priority items corresponding to priorityIds
              const priorityItems = await Promise.all(
                priorityIds.map(
                  (id: string) =>
                    [...results.items].find((item) => item.id === id) ||
                    ladeLieferant(id)
                )
              );

              // Optionally filter priority items by input
              const filteredPriorityItems =
                request.input.trim() !== ""
                  ? priorityItems.filter((item: Lieferant) =>
                      item.name
                        .toLowerCase()
                        .includes(request.input.toLowerCase())
                    )
                  : priorityItems;

              // Remove duplicates from the final list
              const seenIds = new Set(
                filteredPriorityItems.map((item) => item.id)
              );
              const finalResults = [
                ...filteredPriorityItems,
                ...results.items.filter((item) => !seenIds.has(item.id)),
              ];
              callback(undefined, { ...results, items: finalResults });
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchLieferanten]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Lieferant) => {
        if (typeof option === "string") {
          return "";
        } else {
          return option.name;
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
        const lieferant = value as Lieferant;
        if (lieferant) {
          const lieferantId = value ? lieferant.id : "";
          if (lieferantId !== "") {
            let letzteLieferanten = JSON.parse(
              localStorage.getItem("letzteLieferanten") ?? "[]"
            );

            letzteLieferanten = letzteLieferanten.filter(
              (id: string) => id !== lieferantId
            );

            letzteLieferanten.unshift(lieferantId);

            if (letzteLieferanten.length > 10) {
              letzteLieferanten.splice(0, 10);
            }

            localStorage.setItem(
              "letzteLieferanten",
              JSON.stringify(letzteLieferanten)
            );
          }
        }
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Lieferant) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemText
              primary={option.name}
              secondary={[option.strasse, option.ort]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default LieferantenSelect;

import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Benutzer, BenutzerListe } from "../../api";
import { useGetBenutzer, useSucheBenutzer } from "../../client/hooks";

interface BenutzerSelectProps extends FieldProps {
  label: string;
  required?: boolean;
  setBenutzer: (value: string) => void;
}

function FilterBenutzerSelect({
  label,
  required,
  field,
  form,
  meta,
  setBenutzer,
}: BenutzerSelectProps) {
  const searchBenutzer = useSucheBenutzer();
  const ladeBenutzer = useGetBenutzer();
  const [optionen, setOptionen] = useState<ReadonlyArray<Benutzer>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeBenutzer(field.value)
        .then((benutzer) => {
          form.setFieldValue(field.name, benutzer);
          setInputValue(benutzer);
        })
        .catch((error) => {
          console.error("Error loading user:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: BenutzerListe) => void
        ) =>
          searchBenutzer({
            search: request.input,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBenutzer]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([
        { id: "_all", name: "Alle", email: "" },
        ...(results ? results.items : []),
      ]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      sx={{ width: "250px", ml: 2 }}
      field={field}
      form={form}
      meta={meta}
      size={"small"}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Benutzer) => {
        if (typeof option === "string") {
          return "";
        } else {
          return [option.name, option.vorname].filter(Boolean).join(", ");
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
        if (value as Benutzer) {
          setBenutzer(
            typeof value === "string" ? value : (value as Benutzer).id
          );
        }
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Benutzer) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemText
              primary={[option.name, option.vorname].filter(Boolean).join(", ")}
              secondary={[
                option.personalNummer,
                option.email ?? option.geburtsDatum ?? null,
              ]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default FilterBenutzerSelect;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlotProps,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useGetApi } from "../../../client";
import {
  useCreateMaterialCost,
  useGetMaterialCostId,
  useUpdateMaterialKosten,
  useUpdateMaterialZeitraum,
} from "../../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  MaterialKostenCreate,
  MaterialKostenUpdate,
  MaterialKostenZeitraum,
} from "../../../api";
import { useT } from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Material } from "../../../pages/abladestellen/AbladestellenDetails";
import { array, bool } from "yup";
import { act } from "react-dom/test-utils";

const roles = ["Market", "Finance", "Development"];

interface SaveableEntry {
  key: string;
  value: boolean;
}

interface Gueltigkeit {
  rowId: string | null;
  gueltigVon: string | null;
  gueltigBis: string | null;
  vonMinimum: string | null;
  vonMaximum: string | null;
  bisMinimum: string | null;
  bisMaximum: string | null;
  editClickedBool: boolean;
}

const initialRows: GridRowsProp = [
  {
    id: 1,
    gueltigVon: "",
    gueltigBis: "",
    kostenProTonne: 0,
    kostenProKubikmeter: 0,
  },
];

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
    materialId: string;
  }
}

function EditToolbar(props: GridSlotProps["toolbar"]) {
  const { setRows, setRowModesModel, materialId, rows, setGueltigkeit } = props;
  const { t } = useT("material");

  const [counter, setCounter] = useState<number>(2);
  const getId = useGetMaterialCostId();
  const { mutate: mutateZeitraum } =
    useSaveUpdateMaterialCostZeitraum(materialId);
  const handleClick = async () => {
    const identifiable = await getId(materialId);
    const currentDate = new Date().toISOString().split("T")[0];
    setCounter(counter + 1);
    setRows(
      (oldRows: readonly GridValidRowModel[]): readonly GridValidRowModel[] => {
        let gueltigVon = currentDate;
        let firstRow: GridValidRowModel | null = null;

        if (oldRows.length > 0) {
          firstRow = { ...oldRows[0] }; // Clone the first row to avoid mutation
          if (!firstRow.gueltigBis) {
            // Check if gueltigVon exists and is before the current date
            const gueltigVonDate = new Date(firstRow.gueltigVon);
            const today = new Date();
            today.setDate(today.getDate() - 1);

            if (gueltigVonDate < today) {
              // If gueltigVon is before today, set gueltigBis to yesterday
              const gueltigBis = new Date();
              gueltigBis.setDate(gueltigBis.getDate() - 1);
              firstRow.gueltigBis = gueltigBis.toISOString().split("T")[0];
            } else {
              const gueltigBis = gueltigVonDate;
              firstRow.gueltigBis = gueltigBis.toISOString().split("T")[0];
            }
          }
          if (firstRow.gueltigBis) {
            const gueltigBisDate = new Date(firstRow.gueltigBis);
            gueltigBisDate.setDate(gueltigBisDate.getDate() + 1);
            gueltigVon = gueltigBisDate.toISOString().split("T")[0];
          }
        }

        const newRow: GridValidRowModel = {
          id: identifiable.id,
          gueltigVon: gueltigVon,
          gueltigBis: null,
          kostenProTonne: 0,
          kostenProKubikmeter: 0,
          isNew: true,
        };

        setGueltigkeit({
          rowId: identifiable.id,
          gueltigVon: gueltigVon,
          gueltigBis: null,
          vonMinimum: null,
          vonMaximum: null,
          bisMinimum: null,
          bisMaximum: null,
          editClickedBool: false,
        });

        if (oldRows.length > 0) {
          return [
            newRow,
            firstRow,
            ...oldRows.slice(1),
          ] as readonly GridValidRowModel[];
        } else {
          return [newRow] as readonly GridValidRowModel[];
        }
      }
    );

    setRowModesModel((oldModel) => {
      const updatedRowModes = Object.keys(oldModel).reduce((acc, key) => {
        // @ts-ignore
        acc[key] = { mode: GridRowModes.View, ignoreModifications: true };
        return acc;
      }, {});

      return {
        ...updatedRowModes,
        [identifiable.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "gueltigVon",
        },
      };
    });
  };

  const hasUnsavedEntry = (rows: GridRowsProp) => {
    return rows.some((row) => row.isNew); // Prüft, ob eine Zeile mit isNew == true existiert
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        disabled={hasUnsavedEntry(rows)}
      >
        {t("add-entry")}
      </Button>
    </GridToolbarContainer>
  );
}

export default function MaterialKostenGrid({
  materialId,
  abladestellenId,
  material,
}: {
  materialId: string;
  abladestellenId: string;
  material: Material;
}) {
  const getApi = useGetApi();
  const [rows, setRows] = React.useState(initialRows);
  const queryClient = useQueryClient();
  const { mutate } = useSaveCreateMaterialCost(materialId);
  const { mutateAsync } = useSaveUpdateMaterialCost(materialId);
  const { mutate: mutateZeitraum } =
    useSaveUpdateMaterialCostZeitraum(materialId);
  const fetchRows = useCallback(
    async () => {
      try {
        const api = await getApi(); // Get the API instance
        const data = await api.material.getMaterialCosts({
          materialId: materialId,
          search: "",
          page: 0,
          limit: 100,
        });
        // Transform or update data as needed before setting it
        const transformedRows = data.items.map((row: any, index: number) => ({
          id: row.id,
          gueltigVon: row.gueltigVon || "", // Fallback to empty if missing
          gueltigBis: row.gueltigBis || "", // Fallback to empty if missing
          kostenProTonne: row.kostenProTonne ?? 0, // Default to 0 if null
          kostenProKubikmeter: row.kostenProKubikmeter ?? 0, // Default to 0 if null
          isNew: false,
        }));
        if (transformedRows.length > 0) {
          setRows(transformedRows);
        }
      } catch (error) {
        console.error("Error fetching material costs:", error);
      }
    },
    [getApi] // Dependency array ensures fetchRows updates when getApi changes
  );
  useEffect(() => {
    const loadRows = async () => {
      await fetchRows(); // Properly handle the promise
    };
    loadRows();
  }, [fetchRows]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    const updatedRowModes = Object.keys(rowModesModel).reduce((acc, key) => {
      // @ts-ignore
      acc[key] = { mode: GridRowModes.View, ignoreModifications: true };
      return acc;
    }, {});
    // @ts-ignore
    updatedRowModes[id] = { mode: GridRowModes.Edit };

    setGueltigkeit({
      rowId: id.toString(),
      gueltigVon: null,
      gueltigBis: null,
      vonMinimum: null,
      vonMaximum: null,
      bisMinimum: null,
      bisMaximum: null,
      editClickedBool: true,
    });

    setRowModesModel(updatedRowModes);
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };

    if (newRow["isNew"] === true) {
      mutate({
        id: newRow["id"],
        kostenProTonne: newRow["kostenProTonne"],
        kostenProKubikmeter: newRow["kostenProKubikmeter"],
        gueltigVon: newRow["gueltigVon"],
        gueltigBis: newRow["gueltigBis"],
      });
      const firstNonNewRow = rows.find((row) => row.isNew === false);
      if (firstNonNewRow) {
        mutateZeitraum({
          type: "bis",
          datum: firstNonNewRow.gueltigBis + "T00:00:00Z",
          costId: firstNonNewRow["id"],
        });
      }
    } else {
      await mutateAsync({
        id: newRow["id"],
        kostenProTonne: newRow["kostenProTonne"],
        kostenProKubikmeter: newRow["kostenProKubikmeter"],
        gueltigVon: newRow["gueltigVon"],
        gueltigBis: newRow["gueltigBis"],
      });
    }
    await queryClient.invalidateQueries(["abladestellen", abladestellenId]);
    const rowWithNewestDate = rows.reduce((latest, current) => {
      return new Date(current.gueltigVon) > new Date(latest.gueltigVon)
        ? current
        : latest;
    }, rows[0]);

    if (rowWithNewestDate.id === newRow["id"]) {
      material.kostenProKubikmeter = newRow["kostenProKubikmeter"];
      material.kostenProTonne = newRow["kostenProTonne"];
    }

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const { t } = useTranslation("material");
  const [saveable, setSaveable] = useState<SaveableEntry[]>([]);
  const [gueltigkeit, setGueltigkeit] = useState<Gueltigkeit>({
    rowId: null,
    gueltigVon: null,
    gueltigBis: null,
    vonMinimum: null,
    vonMaximum: null,
    bisMinimum: null,
    bisMaximum: null,
    editClickedBool: false,
  });

  function functionToSetSaveable(key: string | null, value: boolean) {
    if (key) {
      setSaveable((prevSaveable) => {
        const index = prevSaveable.findIndex((entry) => entry.key === key);

        if (index !== -1) {
          const updatedSaveable = [...prevSaveable];
          updatedSaveable[index] = { ...updatedSaveable[index], value };
          return updatedSaveable;
        } else {
          return [...prevSaveable, { key, value }];
        }
      });
    }
  }

  useEffect(() => {
    const aktuelleGueltigkeitVon = gueltigkeit.gueltigVon
      ? new Date(gueltigkeit.gueltigVon).setHours(0, 0, 0, 0)
      : null;
    const aktuelleGueltigkeitBis = gueltigkeit.gueltigBis
      ? new Date(gueltigkeit.gueltigBis).setHours(0, 0, 0, 0)
      : null;
    const aktuelleGueltigkeitVonMinimum = gueltigkeit.vonMinimum
      ? new Date(gueltigkeit.vonMinimum).setHours(0, 0, 0, 0)
      : null;
    const aktuelleGueltigkeitVonMaximum = gueltigkeit.vonMaximum
      ? new Date(gueltigkeit.vonMaximum).setHours(0, 0, 0, 0)
      : null;
    const aktuelleGueltigkeitBisMinimum = gueltigkeit.bisMinimum
      ? new Date(gueltigkeit.bisMinimum).setHours(0, 0, 0, 0)
      : null;
    const aktuelleGueltigkeitBisMaximum = gueltigkeit.bisMaximum
      ? new Date(gueltigkeit.bisMaximum).setHours(0, 0, 0, 0)
      : null;

    const isGueltigVonValid: 0 | null | false | boolean =
      aktuelleGueltigkeitVon &&
      (!aktuelleGueltigkeitVonMinimum ||
        aktuelleGueltigkeitVon >= aktuelleGueltigkeitVonMinimum) &&
      (!aktuelleGueltigkeitVonMaximum ||
        aktuelleGueltigkeitVon <= aktuelleGueltigkeitVonMaximum);

    var isGueltigBisValid: boolean = false;

    if (aktuelleGueltigkeitBis == null) {
      isGueltigBisValid = aktuelleGueltigkeitVonMaximum == null;
    } else {
      isGueltigBisValid =
        (!aktuelleGueltigkeitBisMinimum ||
          aktuelleGueltigkeitBis >= aktuelleGueltigkeitBisMinimum) &&
        (!aktuelleGueltigkeitBisMaximum ||
          aktuelleGueltigkeitBis < aktuelleGueltigkeitBisMaximum);
    }

    // @ts-ignore
    const isSaveable: boolean =
      (isGueltigVonValid && isGueltigBisValid) || gueltigkeit.editClickedBool;

    functionToSetSaveable(gueltigkeit.rowId, isSaveable);
  }, [gueltigkeit, rows]);

  const columns: GridColDef[] = [
    {
      field: "gueltigVon",
      headerName: t("valid-from"),
      type: "date",
      width: 180,
      editable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      renderEditCell: (params) => {
        const apiRef = params.api; // Zugriff auf die API
        const currentRowId = params.id; // ID der aktuellen Zeile
        const rowIds = Array.from(apiRef.getRowModels().keys()); // IDs aller Zeilen
        const currentRowIndex = rowIds.indexOf(currentRowId);

        // Get the next row's `gueltigBis` value if it exists
        const nextRowId = rowIds[currentRowIndex + 1];
        const nextRow = nextRowId ? apiRef.getRowModels().get(nextRowId) : null;
        const minDate = nextRow?.gueltigBis
          ? (() => {
              const nextDate = new Date(nextRow.gueltigBis);
              nextDate.setDate(nextDate.getDate() + 1); // Add one day
              return nextDate.toISOString().split("T")[0]; // Format as yyyy-mm-dd
            })()
          : null;
        // Berechne die Min- und Max-Werte basierend auf benachbarten Zeilen
        const maxDate = params.row.gueltigBis
          ? new Date(params.row.gueltigBis).toISOString().split("T")[0]
          : null;
        return (
          <input
            type="date"
            value={params.value ? params.value.toISOString().split("T")[0] : ""}
            min={minDate ? minDate : ""}
            max={maxDate ? maxDate : ""}
            onChange={(e) => {
              if (e.target.value) {
                params.api.setEditCellValue({
                  id: currentRowId,
                  field: "gueltigVon",
                  value: new Date(e.target.value),
                });
                setGueltigkeit({
                  rowId: currentRowId.toString(),
                  gueltigVon: e.target.value,
                  gueltigBis: params.row.gueltigBis,
                  vonMinimum: minDate,
                  vonMaximum: maxDate,
                  bisMinimum: gueltigkeit.bisMinimum,
                  bisMaximum: gueltigkeit.bisMaximum,
                  editClickedBool: false,
                });
              }
            }}
          />
        );
      },
    },
    {
      field: "gueltigBis",
      headerName: t("valid-until"),
      type: "date",
      width: 180,
      editable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null; // Safely returns a Date or null
      },
      renderEditCell: (params) => {
        const apiRef = params.api; // Zugriff auf die API
        const currentRowId = params.id; // ID der aktuellen Zeile
        const rowIds = Array.from(apiRef.getRowModels().keys()); // IDs aller Zeilen
        const currentRowIndex = rowIds.indexOf(currentRowId);

        // Get the next row's `gueltigBis` value if it exists
        const previousRowId = rowIds[currentRowIndex - 1];
        const previousRow = previousRowId
          ? apiRef.getRowModels().get(previousRowId)
          : null;
        const minDate = params.row.gueltigVon
          ? new Date(params.row.gueltigVon).toISOString().split("T")[0]
          : null;
        // Berechne die Min- und Max-Werte basierend auf benachbarten Zeilen
        const maxDate = previousRow?.gueltigVon
          ? new Date(previousRow.gueltigVon).toISOString().split("T")[0]
          : null;
        return (
          <input
            type="date"
            value={params.value ? params.value.toISOString().split("T")[0] : ""}
            min={minDate ? minDate : ""}
            max={maxDate ? maxDate : ""}
            onChange={(e) => {
              if (params.row.isNew == true) {
                params.api.setEditCellValue({
                  id: currentRowId,
                  field: "gueltigBis",
                  value: e.target.value ? new Date(e.target.value) : null,
                });
                setGueltigkeit({
                  rowId: currentRowId.toString(),
                  gueltigVon: params.row.gueltigVon,
                  gueltigBis: e.target.value,
                  vonMinimum: gueltigkeit.vonMinimum,
                  vonMaximum: gueltigkeit.vonMaximum,
                  bisMinimum: minDate,
                  bisMaximum: maxDate,
                  editClickedBool: false,
                });
              } else {
                if (e.target.value) {
                  params.api.setEditCellValue({
                    id: currentRowId,
                    field: "gueltigBis",
                    value: e.target.value ? new Date(e.target.value) : null,
                  });
                  setGueltigkeit({
                    rowId: currentRowId.toString(),
                    gueltigVon: params.row.gueltigVon,
                    gueltigBis: e.target.value,
                    vonMinimum: gueltigkeit.vonMinimum,
                    vonMaximum: gueltigkeit.vonMaximum,
                    bisMinimum: minDate,
                    bisMaximum: maxDate,
                    editClickedBool: false,
                  });
                } else {
                  if (!previousRow) {
                    params.api.setEditCellValue({
                      id: currentRowId,
                      field: "gueltigBis",
                      value: e.target.value ? new Date(e.target.value) : null,
                    });
                  }
                }
              }
            }}
          />
        );
      },
    },
    {
      field: "kostenProTonne",
      headerName: t("costs-per-ton"),
      type: "number",
      width: 180,
      align: "right",
      headerAlign: "right",
      editable: true,
      valueFormatter: (value: number) => {
        return value !== null ? value.toFixed(2).replace(".", ",") : "";
      },
      renderEditCell: (params) => {
        const currentRowId = params.id;
        const value = params.value || 0;
        return (
          <input
            type="number"
            value={value}
            onChange={(e) => {
              const newValue = e.target.value.replace(",", ".");
              params.api.setEditCellValue({
                id: currentRowId,
                field: "kostenProTonne",
                value: newValue,
              });
            }}
            style={{ textAlign: "right", fontSize: "16px" }}
          />
        );
      },
    },
    {
      field: "kostenProKubikmeter",
      headerName: t("costs-per-cbm"),
      type: "number",
      width: 180,
      align: "right",
      headerAlign: "right",
      editable: true,
      valueFormatter: (value: number) => {
        return value !== null ? value.toFixed(2).replace(".", ",") : "";
      },
      renderEditCell: (params) => {
        const currentRowId = params.id;
        const value = params.value || 0;
        return (
          <input
            type="number"
            value={value}
            onChange={(e) => {
              const newValue = e.target.value.replace(",", ".");
              params.api.setEditCellValue({
                id: currentRowId,
                field: "kostenProKubikmeter",
                value: newValue,
              });
            }}
            style={{ textAlign: "right", fontSize: "16px" }}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
              disabled={
                !saveable.find((entry) => entry.key === id)?.value ?? true
              }
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: EditToolbar }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            materialId: materialId,
            rows: rows,
            setGueltigkeit: setGueltigkeit,
          },
        }}
      />
    </Box>
  );
}
function useSaveCreateMaterialCost(materialId: string) {
  const createMaterialCost = useCreateMaterialCost();
  const queryClient = useQueryClient();

  return useMutation(
    (input: MaterialKostenCreate) => {
      return createMaterialCost(materialId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}
function useSaveUpdateMaterialCost(materialId: string) {
  const updateMaterialCost = useUpdateMaterialKosten();
  const queryClient = useQueryClient();

  return useMutation(
    (input: MaterialKostenUpdate) => {
      return updateMaterialCost(materialId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}
function useSaveUpdateMaterialCostZeitraum(materialCostId: string) {
  const updateMaterialCost = useUpdateMaterialZeitraum();
  const queryClient = useQueryClient();

  return useMutation(
    (input: MaterialKostenZeitraum) => {
      return updateMaterialCost(materialCostId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}

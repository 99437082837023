import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  CircularProgress,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import BetriebstagebuecherFieldsContent from "../../components/betriebstagebuecher/BetriebstagebuecherFieldsContent";
import { useGetAccessToken } from "../../client";
import { useQuery } from "react-query";
import { apiUrl } from "../../config";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleBetriebstagebuch() {
  const { t } = useTranslation("betriebstagebuch");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const { refetch } = useCreateBetriebstagebuch(startDate, endDate);

  const handlePreviewClick = async () => {
    if (startDate && endDate) {
      setIsLoadingPreview(true);
      const result = await refetch();
      setIsLoadingPreview(false);
      if (result.data) {
        window.open(result.data, "_blank");
      }
    }
  };

  let schema = yup.object().shape({
    startDatum: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    endDatum: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
  });

  const onSubmit = useCallback(
    async (
      values: { startDatum: string; endDatum: string },
      formikHelpers: FormikHelpers<{ startDatum: string; endDatum: string }>
    ) => {
      const { startDatum, endDatum } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      setSubmitting(true);
      try {
        window.open(
          `${apiUrl}/betriebstagebuch/download?startDatum=${encodeURIComponent(
            startDatum
          )}&endDatum=${encodeURIComponent(endDatum)}`
        );
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );

  return (
    <Layout title={capitalize(t("operational-diary"))} back="/betriebstagebuch">
      <Guard
        permission={"betriebsdokumentation:generate"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              startDatum: "",
              endDatum: new Date().toISOString().split("T")[0],
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ handleChange, isSubmitting, isValidating, isValid, dirty }) => {
              const customHandleChange = (e: React.ChangeEvent<any>) => {
                handleChange(e);
                if (e.target.name === "startDatum") {
                  setStartDate(e.target.value);
                } else if (e.target.name === "endDatum") {
                  setEndDate(e.target.value);
                }
              };

              return (
                <>
                  <Typography variant="h5">
                    {capitalize(t("new-operational-diary"))}
                  </Typography>
                  <DialogContent>
                    <Form id="new-betriebstagebuch">
                      <BetriebstagebuecherFieldsContent
                        handleChange={customHandleChange}
                      />
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <SubmitButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isLoadingPreview}
                      disabled={isValidating || !isValid || !dirty}
                      onClick={handlePreviewClick}
                    >
                      {isLoadingPreview ? (
                        <CircularProgress size={24} />
                      ) : (
                        capitalize(t("preview"))
                      )}
                    </SubmitButton>
                    <SubmitButton
                      form="new-betriebstagebuch"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("create"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

function useCreateBetriebstagebuch(startDate: string, endDate: string) {
  const getAccessToken = useGetAccessToken();

  return useQuery(
    ["create-betriebstagebuch", startDate, endDate],
    async () => {
      const response = await fetch(
        `${apiUrl}/betriebstagebuch/download?startDatum=${encodeURIComponent(
          startDate
        )}&endDatum=${encodeURIComponent(endDate)}`,
        {
          headers: { authorization: `Bearer ${await getAccessToken()}` },
        }
      );
      const blob = await response.blob();
      return window.URL.createObjectURL(blob);
    },
    { enabled: false, suspense: false, cacheTime: 0 }
  );
}

import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { Abfallart } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { EntsorgerFilter } from "../entsorger/EntsorgerDataTable";

export type AbfallartFilter = {
  search?: string;
  filtered?: string;
};

export type Material = {
  id: string;
  key: string;
  name: string;
};

export type Props = Omit<DataRequest<AbfallartFilter>, "filter"> &
  Partial<Pick<DataRequest<AbfallartFilter>, "filter">>;

export default function AbfallartenDataTable({ ...input }: Props) {
  const request = useDataRequest<AbfallartFilter>({ filter: {}, ...input });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <AbfallartenResults
        request={request}
        setFilteredState={setFilteredState}
      />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<EntsorgerFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-abfallart");
            }}
          >
            Abfallart anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-abfallart");
            }}
          >
            Abfallart anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function AbfallartenResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<AbfallartFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}
function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<EntsorgerFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAbfallarten: DataLoader<AbfallartFilter, Abfallart> = useCallback(
    async (params) =>
      (await getApi()).abfallarten.sucheAbfallarten({ ...params }),
    [getApi]
  );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["abfallarten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAbfallarten}
      onRowClick={(row) => {
        navigate("/abfallarten/" + row.id);
      }}
      name={"abfallarten"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<Abfallart>> {
  const { t } = useT("customer");

  return useMemo(
    () => [
      {
        field: "key",
        headerName: "EAK-Schluessel",
        flex: 1,
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 5,
      },
    ],
    [t]
  );
}

import {
  Box,
  Button,
  capitalize,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { EingangsrechnungInfo, RechnungenListe } from "../../api";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import PaidIcon from "@mui/icons-material/Paid";
import DatevProtokolleButton from "../rechnungen/DatevProtokolleButton";
import { Field, Formik } from "formik";
import ZahllaufFilterSelect from "./ZahllaufFilterSelect";
import Guard from "../Guard";
import DataTableWithFilter from "../data/DataTableWithFilter";

export type ZahllaufFilter = {
  search?: string;
  status?: string;
  zahllauf?: string;
  kundenId?: string;
  zahllaufBezahlt?: boolean;
  filtered?: string;
};

export type Props = Omit<DataRequest<ZahllaufFilter>, "filter"> &
  Partial<Pick<DataRequest<ZahllaufFilter>, "filter">>;

interface ZahllaufSummenProps {
  setAnzahlAlleRechnungen: (value: number) => void;
  setSummeAlleRechnungen: (value: number) => void;
  setAnzahlSelectedRechnungen: (value: number) => void;
  setSummeSelectedRechnungen: (value: number) => void;
  setBeinhaltetNichtFreigegebeneRechnungen: (value: boolean) => void;
  setAlleSelectedRechnungen: (value: string[]) => void;
}

export default function ZahllaufDataTable({
  setAnzahlAlleRechnungen,
  setSummeAlleRechnungen,
  setAnzahlSelectedRechnungen,
  setSummeSelectedRechnungen,
  setBeinhaltetNichtFreigegebeneRechnungen,
  setAlleSelectedRechnungen,
  setZahllaufZuweisenModalOpened,
  zahllaufZuweisenModalOpened,
  setZahlungsdateiGenerierenModalOpened,
  zahlungsdateiGenerierenModalOpened,
  ...input
}: ZahllaufSummenProps & {
  setZahllaufZuweisenModalOpened: (value: boolean) => void;
  zahllaufZuweisenModalOpened: boolean;
  setZahlungsdateiGenerierenModalOpened: (value: boolean) => void;
  zahlungsdateiGenerierenModalOpened: boolean;
} & Props) {
  const [filteredState, setFilteredState] = useState("");
  const request = useDataRequest<ZahllaufFilter>({
    filter: { zahllaufBezahlt: false },
    ...input,
  });

  function assignmentModalOpen() {
    setZahllaufZuweisenModalOpened(!zahllaufZuweisenModalOpened);
    setZahlungsdateiGenerierenModalOpened(false);
  }

  function generationModalOpen() {
    setZahlungsdateiGenerierenModalOpened(!zahlungsdateiGenerierenModalOpened);
    setZahllaufZuweisenModalOpened(false);
  }

  return (
    <Stack spacing={2}>
      <FilterComp
        assignmentModalOpen={assignmentModalOpen}
        generationModalOpen={generationModalOpen}
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <ZahllaufResults
        setAnzahlAlleRechnungen={setAnzahlAlleRechnungen}
        setSummeAlleRechnungen={setSummeAlleRechnungen}
        setAnzahlSelectedRechnungen={setAnzahlSelectedRechnungen}
        setSummeSelectedRechnungen={setSummeSelectedRechnungen}
        setBeinhaltetNichtFreigegebeneRechnungen={
          setBeinhaltetNichtFreigegebeneRechnungen
        }
        setAlleSelectedRechnungen={setAlleSelectedRechnungen}
        request={request}
        setFilteredState={setFilteredState}
      />
    </Stack>
  );
}

const belegstati: String[] = [
  "IN_ERKENNUNG",
  "OFFEN",
  "IN_PRUEFUNG",
  "FREIGEGEBEN",
  "ABGELEHNT",
];

function FilterComp({
  assignmentModalOpen,
  generationModalOpen,
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  assignmentModalOpen: () => void;
  generationModalOpen: () => void;
  request: DataRequestState<ZahllaufFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search, status, zahllauf }, setField] = useDebouncedFilter(request);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useT("zahllauf");
  const setZahllaufId = (value: string) => {
    setField("zahllauf", value);
  };

  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>{capitalize(t("document-status"))}</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={status ?? "_all"}
              onChange={(e) =>
                setField(
                  "status",
                  e.target.value !== "_all" ? e.target.value : "_all"
                )
              }
            >
              <MenuItem key="_all" value="_all">
                Alle
              </MenuItem>
              {belegstati.map((belegstatus) => (
                // @ts-ignore
                <MenuItem key={belegstatus} value={belegstatus}>
                  {belegstatus
                    .split("_")
                    .map((wort) =>
                      capitalize(wort.toLowerCase().replace("ue", "ü"))
                    )
                    .join(" ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Formik initialValues={{ zahllauf: zahllauf }} onSubmit={() => {}}>
            <Field
              name="zahllauf"
              component={ZahllaufFilterSelect}
              label={"Zahllauf"}
              required={false}
              setZahllauf={setZahllaufId}
              zahllaufBezahlt={false}
            />
          </Formik>
          <Guard permission={"zahllauf:archiv:view"}>
            <Button
              variant="contained"
              component="label"
              color={"secondary"}
              onClick={generationModalOpen}
            >
              {capitalize(t("generate"))}
            </Button>
          </Guard>
          <Guard permission={"zahllauf:create"}>
            <Button
              variant="contained"
              component="label"
              color={"secondary"}
              onClick={assignmentModalOpen}
            >
              {capitalize(t("assign"))}
            </Button>
          </Guard>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>Belegstatus</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={status ?? "_all"}
              onChange={(e) =>
                setField(
                  "status",
                  e.target.value !== "_all" ? e.target.value : "_all"
                )
              }
            >
              <MenuItem key="_all" value="_all">
                Alle
              </MenuItem>
              {belegstati.map((belegstatus) => (
                // @ts-ignore
                <MenuItem key={belegstatus} value={belegstatus}>
                  {belegstatus
                    .split("_")
                    .map((wort) =>
                      capitalize(wort.toLowerCase().replace("ue", "ü"))
                    )
                    .join(" ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Formik initialValues={{ zahllauf: zahllauf }} onSubmit={() => {}}>
            <Field
              name="zahllauf"
              component={ZahllaufFilterSelect}
              label={"Zahllauf"}
              required={false}
              setZahllauf={setZahllaufId}
              zahllaufBezahlt={false}
            />
          </Formik>
          <Guard permission={"zahllauf:archiv:view"}>
            <Button
              variant="contained"
              component="label"
              sx={{ position: "absolute", top: 0, right: "8em" }}
              color={"secondary"}
              onClick={generationModalOpen}
            >
              {capitalize(t("generate"))}
            </Button>
          </Guard>
          <Guard permission={"zahllauf:create"}>
            <Button
              variant="contained"
              component="label"
              sx={{ position: "absolute", top: 0, right: 0 }}
              color={"secondary"}
              onClick={assignmentModalOpen}
            >
              {capitalize(t("assign"))}
            </Button>
          </Guard>
        </>
      )}
    </Box>
  );
}

function ZahllaufResults({
  setAnzahlAlleRechnungen,
  setSummeAlleRechnungen,
  setAnzahlSelectedRechnungen,
  setSummeSelectedRechnungen,
  setBeinhaltetNichtFreigegebeneRechnungen,
  setAlleSelectedRechnungen,
  request,
  setFilteredState,
}: ZahllaufSummenProps & {
  request: DataRequestState<ZahllaufFilter>;
  setFilteredState: (value: string) => void;
}) {
  return (
    <Results
      request={request}
      zahllaufprops={{
        setAnzahlAlleRechnungen,
        setSummeAlleRechnungen,
        setAnzahlSelectedRechnungen,
        setSummeSelectedRechnungen,
        setBeinhaltetNichtFreigegebeneRechnungen,
        setAlleSelectedRechnungen,
      }}
      setFilteredState={setFilteredState}
    />
  );
}

function Results({
  zahllaufprops,
  request,
  setFilteredState,
}: {
  zahllaufprops: ZahllaufSummenProps;
  request: DataRequestState<ZahllaufFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();

  const [rechnungenIds, setRechnungenIds] = useState<string[]>([]);
  const [alleRechnungen, setAlleRechnungen] = useState<RechnungenListe>();

  const loadEingangsrechnungen: DataLoader<
    ZahllaufFilter,
    EingangsrechnungInfo
  > = useCallback(
    async (params) => {
      const api = await getApi();
      const result = await api.rechnungen.sucheEingangsrechnungen({
        ...params,
      });
      setAlleRechnungen(result);
      return result;
    },
    [getApi]
  );

  useEffect(() => {
    const anzahlAllerRechnungen = alleRechnungen?.items.length ?? 0;
    const summeAllerRechnungen = alleRechnungen?.items.reduce(
      (acc, aktuellerWert) => acc + (aktuellerWert?.rechnungsBetrag ?? 0),
      0
    );
    const anzahlAusgewaehlterRechnungen = rechnungenIds.length;
    const summeAusgewaehlterRechnungen = alleRechnungen?.items
      .filter((rechnung) => rechnungenIds.includes(rechnung.id))
      .reduce(
        (acc, aktuellerWert) => acc + (aktuellerWert?.rechnungsBetrag ?? 0),
        0
      );

    const nichtAlleFreigegeben = alleRechnungen?.items
      .filter((rechnung) => rechnungenIds.includes(rechnung.id))
      .some((rechnung) => rechnung.belegStatus !== "FREIGEGEBEN");

    zahllaufprops.setAnzahlAlleRechnungen(anzahlAllerRechnungen ?? 0);
    zahllaufprops.setSummeAlleRechnungen(summeAllerRechnungen ?? 0);
    zahllaufprops.setAnzahlSelectedRechnungen(
      anzahlAusgewaehlterRechnungen ?? 0
    );
    zahllaufprops.setSummeSelectedRechnungen(summeAusgewaehlterRechnungen ?? 0);
    zahllaufprops.setBeinhaltetNichtFreigegebeneRechnungen(
      nichtAlleFreigegeben ?? true
    );
    zahllaufprops.setAlleSelectedRechnungen(rechnungenIds ?? []);
  }, [rechnungenIds, alleRechnungen]);

  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["zahllauf"]}
      loadData={loadEingangsrechnungen}
      // @ts-ignore
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      onRowSelectionModelChange={(newRowSelectionModel: any) =>
        setRechnungenIds(newRowSelectionModel)
      }
      onRowClick={(row) => navigate(`/eingangsrechnungen/${row.id}`)}
      name={"zahllauf"}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<EingangsrechnungInfo>> {
  const { t } = useT("zahllauf");

  // @ts-ignore
  return useMemo(
    () => [
      {
        field: "zahlungkw",
        headerName: capitalize(t("calendar-week-short")),
        flex: 1,
        type: "number",
      },
      {
        field: "skontoDatum",
        headerName: capitalize(t("discount-due-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "faelligOhneSkonto",
        headerName: capitalize(t("due-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "belegStatus",
        headerName: capitalize(t("document-status")),
        renderCell: (params) => {
          const id = params.row.id;
          switch (params.value) {
            case "IN_ERKENNUNG":
              return (
                <Chip
                  icon={<TextIncreaseIcon />}
                  title={"In Erkennung"}
                  label={"in Erkennung"}
                ></Chip>
              );
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"offen"}
                ></Chip>
              );
            case "IN_PRUEFUNG":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"In Prüfung"}
                  color={"warning"}
                  label={"in Prüfung"}
                ></Chip>
              );
            case "FREIGEGEBEN":
              return (
                <Chip
                  icon={<TaskAltIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"freigegeben"}
                ></Chip>
              );
            case "ABGELEHNT":
              return (
                <Chip
                  icon={<HighlightOffIcon />}
                  title={"Abgelehnt"}
                  color={"error"}
                  label={"abgelehnt"}
                ></Chip>
              );
            case "IN_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"in DATEV"}
                  color={"success"}
                  title={"Verarbeitet durch DATEV"}
                />
              );
            case "VERARBEITUNG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Verarbeitung DATEV"}
                  color={"warning"}
                  title={"Verarbeitung durch DATEV"}
                />
              );
            case "FEHLER_BEI_UEBERTRAG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler DATEV"}
                  color={"error"}
                  title={"Fehler bei Übertrag nach Datev"}
                />
              );
            case "FEHLER_GOOGLE":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler Erkennung"}
                  color={"warning"}
                  title={"Fehler Erkennung Google"}
                />
              );
            case "BEZAHLT":
              return (
                <Chip
                  icon={<PaidIcon />}
                  title={"Bezahlt"}
                  color={"success"}
                  label={"bezahlt"}
                ></Chip>
              );
          }
        },
        flex: 1,
      },
      {
        field: "lieferantName",
        headerName: capitalize(t("supplier")),
        flex: 1,
        type: "string",
      },
      {
        field: "rechnungsNummer",
        headerName: capitalize(t("invoice-number")),
        flex: 1,
        type: "string",
      },
      {
        field: "dateiErstelltAm",
        headerName: capitalize(t("receipt-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "baustellenNummern",
        headerName: capitalize(t("construction-number")),
        flex: 1,
        type: "string",
      },
      {
        field: "baustellenBezeichnungen",
        headerName: capitalize(t("construction")),
        flex: 1,
        type: "string",
      },
      {
        field: "rechnungsBetrag",
        headerName: capitalize(t("payment-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "bruttoBetrag",
        headerName: capitalize(t("payment-amount-gross")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "nettoBetrag",
        headerName: capitalize(t("payment-amount-net")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "steuer",
        headerName: capitalize(t("tax")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value) ? `${value} %` : "";
        },
      },
      {
        field: "skontierfaehigerBetrag",
        headerName: capitalize(t("discountable-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "skontoProzent",
        headerName: capitalize(t("discount-in-percent")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value) ? `${value} %` : "";
        },
      },
      {
        field: "skontoBetrag",
        headerName: capitalize(t("discount-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "prueferNamen",
        headerName: capitalize(t("examiner")),
        flex: 1,
        type: "string",
      },
    ],
    [t]
  );
}

// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialKosten, MaterialKostenCreate } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateMaterialCost(): (
  materialId: string,
  requestBody: MaterialKostenCreate
) => Promise<MaterialKosten> {
  const getAPI = useGetApi();
  return useCallback(
    async (materialId, requestBody) =>
      (await getAPI()).material.createMaterialCost({ materialId, requestBody }),
    [getAPI]
  );
}

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import Select from "@mui/material/Select";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import {
  useDeleteMaterialtransport,
  useGetAbfallart,
  useGetAbladestelle,
  useGetFahrzeug,
  useGetMaterial,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Abladestelle,
  ActionTimestamp,
  Buchungstyp,
  EinheitenListe,
  Entsorger,
  Erzeuger,
  Material,
} from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import Buchungsart from "../../../Buchungsart";
import EntsorgerSelect from "../../../entsorger/EntsorgerSelect";
import ErzeugerSelect from "../../../erzeuger/ErzeugerSelect";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import MaterialEakSelect from "../../../material/MaterialEakSelect";
import Grid from "@mui/material/Grid2";
import EinheitSelect from "../../../einheiten/EinheitSelect";

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelleId?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
  eakSchluessel?: string;
}

export default function MaterialabtransportFieldsContent({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const deleteMaterialtransport = useDeleteMaterialtransport();
  const getAbladestelle = useGetAbladestelle();
  const getMaterial = useGetMaterial();
  const [isDeponie, setIsDeponie] = useState<boolean>(
    materialtransport.fuhrTyp === "DP"
  );
  const [eakSchluessel, setEAKSchluessel] = useState<string>(
    materialtransport.eakSchluessel ?? "-"
  );
  const [isKosten] = useState(materialtransport.buchungsArt === "Kosten");
  const getfahrzeug = useGetFahrzeug();
  const [currentfahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  const [abladestelleId, setAbladestelleId] = useState<string | null>(
    materialtransport?.zielAbladestelle ?? null
  );
  const ladeAbfallart = useGetAbfallart();

  useEffect(() => {
    setAbladestelleId(abladestelleId);
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`,
      null
    );
  }, [setAbladestelleId, abladestelleId]);

  const { setFieldValue } = useFormikContext();
  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.anzahlFuhren`
  );
  const [einheitField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.einheit`
  );
  const [materialField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`
  );
  const [zielAbladestelleField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.zielAbladestelle`
  );
  const [lfsnrField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.lieferscheinNummer`
  );
  const [bsnrField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bsNr`
  );
  const [erzeugerField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.erzeuger`
  );
  const [entsorgerField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.entsorger`
  );
  const [bemerkungField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bemerkung`
  );
  const [buchungsArtField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsArt`
  );
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  useEffect(() => {
    const fetchData = async () => {
      const material = materialField.value as Material;
      if (material) {
        const data = await ladeAbfallart(material.abfallartId);
        setEAKSchluessel(data.key ?? "");
      }
    };
    fetchData();
  }, [materialField.value]);
  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.mengeGesamt`,
      mengeField.value * fuhrenField.value
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.preisGesamt`,
      (mengeField.value * fuhrenField.value * preisField.value).toFixed(2)
    );
  }, [mengeField.value, fuhrenField.value, preisField.value]);
  useEffect(() => {
    if (abladestelleId && !materialtransport.id) {
      getAbladestelle(abladestelleId).then((abladestelle) => {
        if (abladestelle.isDeponie) {
          setIsDeponie(abladestelle.isDeponie);
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          );
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          );
        }
      });
    }
  }, [abladestelleId]);
  useEffect(() => {
    if (!materialtransport.id) {
      if (einheitField.value === "TO") {
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          )
            .then((material: Material) => {
              if ((material?.kostenProTonne ?? 0) > 0) {
                setFieldValue(
                  `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`,
                  material.kostenProTonne?.toFixed(2) ?? ""
                );
              }
            })
            .catch((error) => {
              console.error("Error loading material:", error);
            });
        }
      }
      if (einheitField.value === "CB") {
        if (fahrzeugId) {
          getfahrzeug(fahrzeugId).then((fahrzeug) => {
            if ((fahrzeug?.ladeMenge ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`,
                fahrzeug.ladeMenge ?? ""
              );
            }
          });
        }
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            if ((material?.kostenProKubikmeter ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`,
                material.kostenProKubikmeter?.toFixed(2) ?? ""
              );
            }
          });
        }
      }
    }
  }, [einheitField.value, materialField.value]);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-removal")}
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 3, md: 3 }}>
            {materialtransport.quelleBaustelle ? (
              <FastField
                component={BaustellenSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.quelleBaustelle`}
                label={t("vehicle-unloading-point")}
                required
              />
            ) : (
              <FastField
                component={AbladestellenSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.zielAbladestelle`}
                label={t("vehicle-unloading-point")}
                required
                setAbladestelleId={setAbladestelleId}
              />
            )}
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            {abladestelleId ? (
              <FastField
                component={MaterialEakSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`}
                label={t("material")}
                abladestelleId={abladestelleId}
                setAbfallartKey={(value: string) => {
                  setEAKSchluessel(value);
                }}
                required
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Material *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Material"
                  disabled={true}
                ></Select>
              </FormControl>
            )}
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Stack>
              <Typography>EAK-Schlüssel</Typography>
              <Box>{eakSchluessel}</Box>
            </Stack>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch
                  checked={isDeponie}
                  onChange={(e) => {
                    setIsDeponie(e.target.checked);
                    setFieldValue(
                      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
                      e.target.checked ? "DP" : "AB"
                    );
                    setFieldValue(
                      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
                      e.target.checked ? "DP" : "AB"
                    );
                  }}
                />
              }
              label={"Ist Deponie"}
            />
          </Grid>
          <Grid container size={{ xs: 3, md: 3 }} alignItems="left">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                push({
                  zielAbladestelle:
                    typeof zielAbladestelleField.value === "string"
                      ? zielAbladestelleField.value
                      : (zielAbladestelleField.value as Abladestelle)?.id ?? "",
                  material:
                    typeof materialField.value === "string"
                      ? materialField.value
                      : (materialField.value as Material)?.id ?? "",
                  anzahlFuhren: fuhrenField.value ?? 1,
                  einheit: einheitField.value ?? "CB",
                  menge: mengeField.value ?? 0,
                  kostenProEinheit: preisField.value ?? 0,
                  lieferscheinNummer: lfsnrField.value ?? "",
                  bsNr: bsnrField.value ?? "",
                  erzeuger:
                    typeof erzeugerField.value === "string"
                      ? erzeugerField.value
                      : (erzeugerField.value as Erzeuger)?.id ?? "",
                  entsorger:
                    typeof entsorgerField.value === "string"
                      ? entsorgerField.value
                      : (entsorgerField.value as Entsorger)?.id ?? "",
                  fuhrTyp: "AB",
                  buchungsTyp: "AB",
                  meLadeVolumenFahrzeug: currentfahrzeugLadeMenge ?? 0,
                  bemerkung: bemerkungField.value ?? "",
                  buchungsArt: buchungsArtField.value ?? "Kosten",
                });
              }}
            >
              <AddCircleIcon />
              {t("vehicle-transport-ab-dp")}
            </Button>
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={3}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => {
                const materialransportId = materialtransport.id;
                if (materialransportId) {
                  deleteMaterialtransport(
                    materialtransport.taetigkeitsberichtId,
                    materialtransport.fuhrparkeintragId,
                    materialransportId
                  ).then(() => {
                    remove(materialTransportIndex);
                  });
                } else {
                  remove(materialTransportIndex);
                }
              }}
              aria-label="delete"
            >
              <DeleteIcon />
              {t("vehicle-transport-ab-dp")}
            </Button>
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("bs-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bsNr`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={ErzeugerSelect}
              label={t("erz-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.erzeuger`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={EntsorgerSelect}
              label={t("en-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.entsorger`}
            />
          </Grid>
          <Grid size={3}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-remark")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bemerkung`}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}

import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateAbwesenheit } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AbwesenheitDaten, Benutzer } from "../../api";
import AbwesenheitenFieldsContent from "../../components/abwesenheiten/AbwesenheitenFieldsContent";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleAbwesenheit() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveAbwesenheit();
  let schema = yup.object().shape({
    start: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    ende: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    mitarbeiter: yup.object().required(),
    bemerkung: yup.string(),
    type: yup.string(),
    brzKey: yup.string().required(),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: {
        start: string;
        ende: string;
        mitarbeiter: any;
        bemerkung: string;
        type: string;
        brzKey: string;
      },
      formikHelpers: FormikHelpers<{
        start: string;
        ende: string;
        mitarbeiter: any;
        bemerkung: string;
        type: string;
        brzKey: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const mitarbeiterId =
        typeof values.mitarbeiter === "string"
          ? values.mitarbeiter
          : (values.mitarbeiter as Benutzer).id ?? "";
      if (mitarbeiterId !== "") {
        await mutateAsync({
          mitarbeiterId: mitarbeiterId,
          start: values.start,
          ende: values.ende,
          type: values.type,
          bemerkung: values.bemerkung,
          brzKey: values.brzKey,
        });
        navigate(`/abwesenheit`);
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Abwesenheit anlegen"} back="/abwesenheit">
      <Guard
        permission={"abwesenheit:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              start: "",
              ende: "",
              mitarbeiter: "",
              bemerkung: "",
              type: "",
              brzKey: "U",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <Typography variant="h5">Abwesenheit anlegen</Typography>
                <DialogContent>
                  <Form id="new-abwesenheit">
                    <AbwesenheitenFieldsContent isVerwaltung={true} />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-abwesenheit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

function useSaveAbwesenheit() {
  const createAbwesenheit = useCreateAbwesenheit();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AbwesenheitDaten) => {
      return createAbwesenheit(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["abwesenheiten"]);
      },
    }
  );
}

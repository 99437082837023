import {
  capitalize,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
} from "../data";
import { useT } from "../../i18n";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { Timestamp } from "../../api";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatevProtokolleButton from "../rechnungen/DatevProtokolleButton";
import PaidIcon from "@mui/icons-material/Paid";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";

export type ZahllaufRechnungenModalFilter = {
  zahllaufId: string;
  search?: string;
};

export type EingangsrechnungenProps = {
  id: string;
  dateiname?: string;
  dateiErstelltAm?: Timestamp;
  rechnungsNummer?: string;
  rechnungsDatum?: Timestamp;
  baustellenId?: string;
  baustellenNamen?: string;
  baustellenNummern?: string;
  baustellenBezeichnungen?: string;
  prueferId?: string;
  lieferantId?: string;
  lieferantName?: string;
  geloeschtAm?: Timestamp;
  geloeschtVon?: string;
  nettoBetrag?: number;
  bruttoBetrag?: number;
  prueferNamen?: string;
  belegStatus?: string;
  pruefStatus?: string;
  belegTyp?: string;
  steuernummer?: string;
  umsatzsteuerId?: string;
  prueferIds?: string;
  skontoDatum?: Timestamp;
  faelligOhneSkonto?: Timestamp;
  steuer?: number;
  skontoProzent?: number;
  rechnungsBetrag?: number;
  rechnungsbetragAbweichend?: boolean;
  zahllaufId?: string;
  zahlungkw?: string;
  zahllaufBezahlt?: boolean;
  skontierfaehigerBetrag?: number;
  skontoBetrag?: number;
  iban?: string;
  kundenNummer?: string;
};

export type Props = Omit<DataRequest<ZahllaufRechnungenModalFilter>, "filter"> &
  Partial<Pick<DataRequest<ZahllaufRechnungenModalFilter>, "filter">> & {
    zahllaufId: string;
  };

function ZahllaufRechnungenModal({
  setOpenedModal,
  zahllaufId,
  ...input
}: { setOpenedModal: (value: boolean) => void } & Props) {
  const { t } = useT("zahllauf");
  const request = useDataRequest<ZahllaufRechnungenModalFilter>({
    filter: { zahllaufId: zahllaufId },
    ...input,
  });

  return (
    <>
      <Paper elevation={1} sx={{ padding: 3 }}>
        <IconButton
          sx={{ float: "right" }}
          onClick={() => setOpenedModal(false)}
        >
          <CloseIcon />
        </IconButton>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="h5">{capitalize(t("invoices"))}</Typography>
          <ZahllaufRechnungenResults
            setOpenedModal={setOpenedModal}
            {...request}
          />
        </Stack>
      </Paper>
    </>
  );
}

function ZahllaufRechnungenResults({
  setOpenedModal,
  ...request
}: {
  setOpenedModal: (value: boolean) => void;
} & DataRequestState<ZahllaufRechnungenModalFilter>) {
  return <Results setOpenedModal={setOpenedModal} request={request} />;
}
function Results({
  setOpenedModal,
  request,
}: {
  setOpenedModal: (value: boolean) => void;
  request: DataRequestState<ZahllaufRechnungenModalFilter>;
}) {
  const getApi = useGetApi();
  const navigate = useNavigate();

  const ladeRechnungenZuZahllauf: DataLoader<
    ZahllaufRechnungenModalFilter,
    EingangsrechnungenProps
  > = useCallback(
    async (params) =>
      (await getApi()).zahllaeufe.sucheRechnungenZuZahllauf({ ...params }),
    [getApi]
  );

  return (
    <>
      <DataTable
        columns={useColumns()}
        request={request}
        queryKey={["zahllaeufe"]}
        loadData={ladeRechnungenZuZahllauf}
        onRowClick={(row) => {
          setOpenedModal(false);
          navigate(`/eingangsrechnungen/${row.id}`);
        }}
        name={"zahllaeufe"}
      />
    </>
  );
}
function useColumns(): Array<GridColDef<EingangsrechnungenProps>> {
  const { t, capitalize } = useT("zahllauf");

  return useMemo(
    () => [
      {
        field: "skontoDatum",
        headerName: capitalize(t("discount-due-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "faelligOhneSkonto",
        headerName: capitalize(t("due-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "belegStatus",
        headerName: capitalize(t("document-status")),
        renderCell: (params) => {
          const id = params.row.id;
          switch (params.value) {
            case "IN_ERKENNUNG":
              return (
                <Chip
                  icon={<TextIncreaseIcon />}
                  title={"In Erkennung"}
                  label={"in Erkennung"}
                ></Chip>
              );
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"offen"}
                ></Chip>
              );
            case "IN_PRUEFUNG":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"In Prüfung"}
                  color={"warning"}
                  label={"in Prüfung"}
                ></Chip>
              );
            case "FREIGEGEBEN":
              return (
                <Chip
                  icon={<TaskAltIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"freigegeben"}
                ></Chip>
              );
            case "ABGELEHNT":
              return (
                <Chip
                  icon={<HighlightOffIcon />}
                  title={"Abgelehnt"}
                  color={"error"}
                  label={"abgelehnt"}
                ></Chip>
              );
            case "IN_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"in DATEV"}
                  color={"success"}
                  title={"Verarbeitet durch DATEV"}
                />
              );
            case "VERARBEITUNG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Verarbeitung DATEV"}
                  color={"warning"}
                  title={"Verarbeitung durch DATEV"}
                />
              );
            case "FEHLER_BEI_UEBERTRAG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler DATEV"}
                  color={"error"}
                  title={"Fehler bei Übertrag nach Datev"}
                />
              );
            case "FEHLER_GOOGLE":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler Erkennung"}
                  color={"warning"}
                  title={"Fehler Erkennung Google"}
                />
              );
            case "BEZAHLT":
              return (
                <Chip
                  icon={<PaidIcon />}
                  title={"Bezahlt"}
                  color={"success"}
                  label={"bezahlt"}
                ></Chip>
              );
          }
        },
        flex: 1,
      },
      {
        field: "lieferantName",
        headerName: capitalize(t("supplier")),
        flex: 1,
        type: "string",
      },
      {
        field: "rechnungsNummer",
        headerName: capitalize(t("invoice-number")),
        flex: 1,
        type: "string",
      },
      {
        field: "dateiErstelltAm",
        headerName: capitalize(t("receipt-date")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "baustellenNummern",
        headerName: capitalize(t("construction-number")),
        flex: 1,
        type: "string",
      },
      {
        field: "baustellenBezeichnungen",
        headerName: capitalize(t("construction")),
        flex: 1,
        type: "string",
      },
      {
        field: "rechnungsBetrag",
        headerName: capitalize(t("payment-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "bruttoBetrag",
        headerName: capitalize(t("payment-amount-gross")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "nettoBetrag",
        headerName: capitalize(t("payment-amount-net")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "steuer",
        headerName: capitalize(t("tax")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value) ? `${value} %` : "";
        },
      },
      {
        field: "skontierfaehigerBetrag",
        headerName: capitalize(t("discountable-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "skontoProzent",
        headerName: capitalize(t("discount-in-percent")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value) ? `${value} %` : "";
        },
      },
      {
        field: "skontoBetrag",
        headerName: capitalize(t("discount-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "prueferNamen",
        headerName: capitalize(t("examiner")),
        flex: 1,
        type: "string",
      },
    ],
    [t, capitalize]
  );
}

export default ZahllaufRechnungenModal;

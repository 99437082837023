import { Box, Divider, Stack, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import KundenSelect from "../kunden/KundenSelect";

export default function BaustellenFieldsContent({ isNew }: { isNew: boolean }) {
  const { t } = useT("customer");
  const { setFieldValue } = useFormikContext();

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "2fr 1fr "], gap: 2 }}
      >
        <Field component={TextField} label="Bezeichnung" name="bezeichnung" />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field component={TextField} label="Name *" name="name" />
        <Field component={KundenSelect} name="kunde" label="Kunde" />
      </Box>
      <Typography sx={{ paddingTop: 2 }}>{t("address")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2 }}
      >
        <Field component={TextField} label={t("street")} name="strasse" />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field component={TextField} label={t("plz")} name="plz" />
        <Field component={TextField} label={t("city") + " *"} name="ort" />
      </Box>
      <Typography sx={{ paddingTop: 2 }}>{t("communication")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2 }}
      >
        <Field component={TextField} label={t("phone")} name="telefon" />
        <Field component={TextField} label={t("email")} name="email" />
      </Box>
      <Typography sx={{ paddingTop: 2 }}>{t("total_other")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={TextField}
          label={t("order-total")}
          name="auftragsSumme"
          onFocus={(event: any) => {
            const formattedValue = parseFloat(
              event.target.value.replace(",", ".")
            );
            setFieldValue(
              "gesamtPreis",
              formattedValue
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
          }}
          inputProps={{ style: { textAlign: "right" } }}
        />
        {isNew ? (
          <></>
        ) : (
          <Field
            component={TextField}
            label={t("balance")}
            name="saldo"
            inputProps={{ readOnly: true, style: { textAlign: "right" } }}
          />
        )}
      </Box>
    </Stack>
  );
}

// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type DeponieListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheMaterialtransporteDeponieQuery = {
  search?: string;
  rechnungId?: string;
  baustelleId?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheMaterialtransporteDeponie(): (
  query: SucheMaterialtransporteDeponieQuery
) => Promise<DeponieListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).materialtransporte.sucheMaterialtransporteDeponie({
        ...query,
      }),
    [getAPI]
  );
}

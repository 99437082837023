import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Fahrzeug, FahrzeugePage, HerkunftTyp } from "../../../api";
import { useGetFahrzeug, useSucheFahrzeuge } from "../../../client/hooks";

interface FahrzeugSelectProps extends FieldProps {
  fahrzeugHerkunft: HerkunftTyp;
  label: string;
  required?: boolean;
}

function FahrzeugSelect({
  fahrzeugHerkunft,
  label,
  required,
  field,
  form,
  meta,
}: FahrzeugSelectProps) {
  const searchFahrzeuge = useSucheFahrzeuge();
  const ladeFahrzeug = useGetFahrzeug();
  const [optionen, setOptionen] = useState<ReadonlyArray<Fahrzeug>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeFahrzeug(field.value)
        .then((fahrzeug) => {
          form.setFieldValue(field.name, fahrzeug);
          setInputValue(fahrzeug);
        })
        .catch((error) => {
          console.error("Error loading vehicle:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: FahrzeugePage) => void
        ) =>
          searchFahrzeuge({
            fahrzeugHerkunft: fahrzeugHerkunft,
            search: request.input,
            page: 0,
            limit: 20,
            sort: "kennzeichen",
          })
            .then(async (results) => {
              const priorityIds = JSON.parse(
                localStorage.getItem("letzteFahrzeuge") ?? "[]"
              );

              // Fetch all priority items corresponding to priorityIds
              const priorityItems = await Promise.all(
                priorityIds.map(
                  (id: string) =>
                    [...results.items].find((item) => item.id === id) ||
                    ladeFahrzeug(id)
                )
              );

              const filteredPriorityItems =
                request.input.trim() === ""
                  ? priorityItems.filter(
                      (item: Fahrzeug) => item.herkunft === fahrzeugHerkunft
                    )
                  : [];

              const seenIds = new Set(
                filteredPriorityItems.map((item) => item.id)
              );

              const finalResults = [
                ...filteredPriorityItems,
                ...results.items.filter((item) => !seenIds.has(item.id)),
              ];
              callback(undefined, { ...results, items: finalResults });
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchFahrzeuge]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Fahrzeug) => {
        if (typeof option === "string") {
          return "";
        } else {
          return optionLabelPrimary(option);
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
        const fahrzeug = value as Fahrzeug;
        if (fahrzeug) {
          const fahrzeugId = value ? fahrzeug.id : "";
          if (fahrzeugId !== "") {
            let letzteFahrzeuge = JSON.parse(
              localStorage.getItem("letzteFahrzeuge") ?? "[]"
            );

            letzteFahrzeuge = letzteFahrzeuge.filter(
              (id: string) => id !== fahrzeugId
            );

            letzteFahrzeuge.unshift(fahrzeugId);

            if (letzteFahrzeuge.length > 10) {
              letzteFahrzeuge.splice(0, 10);
            }

            localStorage.setItem(
              "letzteFahrzeuge",
              JSON.stringify(letzteFahrzeuge)
            );
          }
        }
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Fahrzeug) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemText
              primary={optionLabelPrimary(option)}
              secondary={[option.gruppe, option.fabrikant, option.fahrzeugTyp]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

function optionLabelPrimary(option: Fahrzeug): string {
  const label = [option.fabrikant, option.fahrzeugTyp, option.laufendeNummer]
    .filter(Boolean)
    .join(", ");

  switch (option.gruppe) {
    case "BAGGER":
      return label;
    case "RADLADER":
      return label;
    case "TELESKOP":
      return label;
    case "SIEBANLAGE":
      return label;
    case "WALZE":
      return label;
    case "BRECHER":
      return label;
    case "KOMPAKTLADER":
      return label;
    default:
      return option.kennzeichen ?? "";
  }
}

export default FahrzeugSelect;

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { AbwesenheitInfo } from "../../api";
import FilterBenutzerSelect from "../benutzer/FilterBenutzerSelect";
import { Field, Formik } from "formik";
import { useApproveMultipleAbwesenheiten } from "../../client/hooks";
import { useQueryClient } from "react-query";
import DataTableWithFilter from "../data/DataTableWithFilter";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";

export type AnwesenheitenFilter = {
  search?: string;
  mitarbeiterId?: string;
  filtered?: string;
};

interface AbwesenheitenProps {
  abwesenheitIds: string[];
  setAbwesenheitIds: (value: string[]) => void;
}

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function AbwesenheitDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });
  const [abwesenheitIds, setAbwesenheitIds] = useState<string[]>([]);

  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        abwesenheitIds={abwesenheitIds}
        setAbwesenheitIds={setAbwesenheitIds}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
        {...request}
      />
      <AbwesenheitsResults
        abwesenheitIds={abwesenheitIds}
        setAbwesenheitIds={setAbwesenheitIds}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
        {...request}
      />
    </Stack>
  );
}

function FilterComp({
  abwesenheitIds,
  setAbwesenheitIds,
  filteredStateFilter,
  setFilteredState,
  ...request
}: AbwesenheitenProps & {
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
} & DataRequestState<AnwesenheitenFilter>) {
  const [{ search, mitarbeiterId }, setField] = useDebouncedFilter(request);
  const setMitarbeiterId = (value: string) => {
    setField("mitarbeiterId", value);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const approve = useApproveMultipleAbwesenheiten();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);

  async function approveAbwesenheiten(abwesenheitsIds: Array<string>) {
    await approve(abwesenheitsIds);
    queryClient.invalidateQueries(["abwesenheiten"]);
    navigate(`/abwesenheit`);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{ mitarbeiterId: mitarbeiterId }}
            onSubmit={() => {}}
          >
            <Field
              component={FilterBenutzerSelect}
              name={"mitarbeiterId"}
              label={"Mitarbeiter"}
              setBenutzer={setMitarbeiterId}
            ></Field>
          </Formik>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-abwesenheit");
            }}
          >
            Abwesenheit anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{ mitarbeiterId: mitarbeiterId }}
            onSubmit={() => {}}
          >
            <Field
              component={FilterBenutzerSelect}
              name={"mitarbeiterId"}
              label={"Mitarbeiter"}
              setBenutzer={setMitarbeiterId}
            ></Field>
          </Formik>
          <ButtonGroup sx={{ position: "absolute", top: 0, right: 0 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}
              onClick={() => approveAbwesenheiten(abwesenheitIds)}
            >
              Abwesenheiten freigeben
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/neu-abwesenheit");
              }}
            >
              Abwesenheit anlegen
            </Button>
          </ButtonGroup>
        </>
      )}
    </Box>
  );
}

function AbwesenheitsResults({
  abwesenheitIds,
  setAbwesenheitIds,
  filteredStateFilter,
  setFilteredState,
  ...request
}: AbwesenheitenProps & {
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
} & DataRequestState<AnwesenheitenFilter>) {
  return (
    <Results
      abwesenheitIds={abwesenheitIds}
      setAbwesenheitIds={setAbwesenheitIds}
      request={request}
      setFilteredState={setFilteredState}
    />
  );
}

function Results({
  abwesenheitIds,
  setAbwesenheitIds,
  request,
  setFilteredState,
}: AbwesenheitenProps & {
  request: DataRequestState<AnwesenheitenFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAbwesenheiten: DataLoader<AnwesenheitenFilter, AbwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).abwesenheiten.sucheAbwesenheiten({ ...params }),
      [getApi]
    );

  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["abwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAbwesenheiten}
      name={"abwesenheit"}
      // @ts-ignore
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      onRowSelectionModelChange={(newRowSelectionModel: any) => {
        setAbwesenheitIds(newRowSelectionModel);
      }}
      onRowClick={(row) => {
        navigate("/abwesenheit/" + row.id);
      }}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<AbwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
      },
      {
        field: "type",
        headerName: "Typ",
      },
      {
        field: "start",
        headerName: "Start",
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "tage",
        headerName: "Tage",
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "erstelltAm",
        headerName: "erstellt am",
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "bemerkung",
        headerName: "Bemerkung",
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          switch (params.value) {
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"Offen"}
                ></Chip>
              );
            case "ANGENOMMEN":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"Freigegeben"}
                ></Chip>
              );
          }
        },
      },
    ],
    [t]
  );
}

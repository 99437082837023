import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useUpdateMeineAnwesenheit } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { MyAnwesenheitDaten } from "../../api";
import AnwesenheitFieldsContent from "../../components/anwesenheiten/AnwesenheitFieldsContent";
import { useAnwesenheit } from "../../client/queries";
import DeleteAnwesenheitButton from "../../components/anwesenheiten/DeleteAnwesenheitButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function DetailsMeineAnwesenheit() {
  const { t } = useTranslation("customer");
  const { id: anwesenheitId = "" } = useParams<{
    id: string;
  }>();
  const anwesenheit = useAnwesenheit(anwesenheitId);
  const { mutateAsync } = useSaveAnwesenheit(anwesenheitId);
  let schema = yup.object().shape({
    datum: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    start: yup.string(),
    ende: yup.string(),
    bezahltePause: yup.number(),
    unbezahltePause: yup.number(),
    fahrzeiten: yup.number(),
    arbeitszeitBrutto: yup.string(),
    arbeitszeitNetto: yup.string(),
    mitarbeiter: yup.object(),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: {
        datum: string;
        start: string;
        ende: string;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: string;
        arbeitszeitNetto: string;
      },
      formikHelpers: FormikHelpers<{
        datum: string;
        start: string;
        ende: string;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: string;
        arbeitszeitNetto: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      if (values.start !== "" && values.ende !== "") {
        const anwesenheit = await mutateAsync({
          datum: values.datum,
          start: values.start,
          ende: values.ende,
          bezahltePause: values.bezahltePause,
          unbezahltePause: values.unbezahltePause,
          fahrzeiten: values.fahrzeiten,
          arbeitszeitBrutto: parseFloat(
            values.arbeitszeitBrutto.replace(",", ".")
          ),
          arbeitszeitNetto: parseFloat(
            values.arbeitszeitNetto.replace(",", ".")
          ),
        });
        navigate(`/meine-anwesenheiten`);
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  return (
    <Layout title={"Anwesenheit"} back={"/meine-anwesenheiten"}>
      <Guard
        permission={"meine:anwesenheit:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          <Guard permission={"meine:anwesenheit:delete"}>
            <DeleteAnwesenheitButton
              id={anwesenheitId}
            ></DeleteAnwesenheitButton>
          </Guard>
          <Formik
            initialValues={{
              datum: anwesenheit.datum ?? getCurrentDate(),
              start: anwesenheit.start,
              ende: anwesenheit.ende,
              bezahltePause: anwesenheit.bezahltePause ?? 0,
              unbezahltePause: anwesenheit.unbezahltePause ?? 0,
              fahrzeiten: anwesenheit.fahrzeiten ?? 0,
              arbeitszeitBrutto:
                formatNumber(anwesenheit.arbeitszeitBrutto) ?? "0",
              arbeitszeitNetto:
                formatNumber(anwesenheit.arbeitszeitNetto) ?? "0",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <Typography variant="h5">
                  {anwesenheit.geloeschtAm === undefined ? (
                    "Anwesenheit"
                  ) : (
                    <>
                      <>{"Anwesenheit "}</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                <DialogContent>
                  <Form id="new-anwesenheit">
                    <AnwesenheitFieldsContent
                      isVerwaltungAnwesenheit={false}
                      startzeit={values.start}
                      endzeit={values.ende}
                    />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-anwesenheit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveAnwesenheit(id: string) {
  const updatedAnwesenheit = useUpdateMeineAnwesenheit();
  const queryClient = useQueryClient();

  return useMutation(
    (input: MyAnwesenheitDaten) => {
      return updatedAnwesenheit(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anwesenheiten"]);
      },
    }
  );
}

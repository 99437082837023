import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  capitalize,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import React, { useCallback, useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  useAssignBaustelleZuRechnung,
  useGetZugewieseneBaustellen,
  useUpdateRechnung,
  useUpdateZugewieseneBaustelle,
} from "../../client/hooks";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import {
  Baustelle,
  Benutzer,
  Lieferant,
  Rechnung,
  RechnungDaten,
} from "../../api";
import {
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
  useField,
  useFormikContext,
} from "formik";
import * as yup from "yup";
import * as Yup from "yup";
import { Select, TextField } from "formik-mui";
import LieferantenSelect from "../../components/lieferanten/LieferantenSelect";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetAccessToken } from "../../client";
import { apiUrl } from "../../config";
import { useNavigate, useParams } from "react-router";
import { useRechnung } from "../../client/queries";
import BaustellenEintrag from "../../components/eingangsrechnungen/BaustellenEintrag";
import SubmitButton from "../../components/SubmitButton";
import DeleteRechnungenButton from "../../components/rechnungen/DeleteRechnungenButton";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import DublettenCheck from "../../components/rechnungen/DublettenCheck";
import SkontoFields from "../../components/rechnungen/SkontoFields";
import { number } from "yup";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function RechnungsFormular() {
  const { t } = useT("eingangsrechnung");
  const { id: rechnungId = "" } = useParams<{
    id: string;
  }>(); // @ts-ignore
  const { isLoading, data } = useGetAnhang(rechnungId) as UseQueryResult<Blob>;
  const rechnung = useRechnung(rechnungId);
  const navigate = useNavigate();
  const [baustellenEintraege, setBaustellenEintraege] =
    useState<ZugewieseneBaustelleUpdated[]>();
  const fetchZugewieseneBaustellen = useGetZugewieseneBaustellen();
  const { mutateAsync } = useSaveRechnung(rechnungId);
  const createBaustellenEintrag = useAssignBaustelleZuRechnung();
  const updateBaustellenEintrag = useUpdateZugewieseneBaustelle();
  const zoomPluginInstance = zoomPlugin({ enableShortcuts: false });
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    if (data) {
      setFileUrl(URL.createObjectURL(data));
    }
  }, [isLoading]);
  useEffect(() => {
    const fetchZugewiesene = async () => {
      const baustellenData = await fetchZugewieseneBaustellen(rechnungId, {
        search: "",
        page: 0,
        limit: 20,
      });
      if (baustellenData.items.length > 0) {
        setBaustellenEintraege(
          baustellenData.items.map((item) => {
            return {
              id: item.id,
              rechnungsId: item.rechnungsId,
              baustelle: item.baustellenId,
              pruefer: item.prueferId,
              pruefStatus: item.pruefStatus,
              betrag: formatNumber(item.betrag),
              leistung: item.leistung,
            };
          })
        );
      } else {
        setBaustellenEintraege([
          {
            id: "",
            rechnungsId: rechnungId,
            baustelle: "",
            pruefer: "",
            betrag: "",
            pruefStatus: "offen",
            leistung: "",
          },
        ]);
      }
    };
    fetchZugewiesene();
  }, [rechnungId, fetchZugewieseneBaustellen]);

  let schema = yup.object().shape({
    belegtyp: yup.string().required(),
    rechnungsnummer: yup.string(),
    rechnungsdatum: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    rechnungseingang: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    bruttobetrag: yup.string(),
    nettobetrag: yup.string(),
    rechnungsBetrag: yup.string(),
    rechnungsbetragAbweichend: yup.boolean(),
    umsatzsteuer: yup.string(),
    lieferant: yup.object(),
    kundennummer: yup.string(),
    steuer: yup.number(),
    steuernummer: yup.string(),
    umsatzsteuerId: yup.string(),
    pruefStatus: yup.string(),
    iban: yup.string(),
    geschaefspartnerKonto: yup.string(),
    skonto1InProzent: yup.string(),
    skontoInEuro: yup.string(),
    faelligkeitMitSkonto: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    faelligkeitOhneSkonto: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    baustellenEintrag: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          rechnungsId: Yup.string(),
          baustelle: Yup.object(),
          pruefer: Yup.object(),
          pruefStatus: Yup.string(),
          betrag: Yup.string(),
          leistung: Yup.string(),
        })
      )
      .min(0, ""),
  });

  interface ZugewieseneBaustelleUpdated {
    id: string;
    rechnungsId: string | undefined;
    baustelle: any | undefined;
    pruefer: any | undefined;
    pruefStatus: string | undefined;
    betrag: string;
    leistung: string | undefined;
  }

  const onSubmit = useCallback(
    async (
      values: {
        belegtyp: string;
        rechnungsnummer: string;
        rechnungsdatum: string;
        rechnungseingang: string;
        bruttobetrag: string;
        rechnungsBetrag: string;
        rechnungsbetragAbweichend: boolean;
        nettobetrag: string;
        umsatzsteuer: string;
        lieferant: any;
        kundennummer: string;
        steuer: number;
        steuernummer: string;
        umsatzsteuerId: string;
        pruefStatus: string;
        iban: string;
        geschaefspartnerKonto: string;
        skonto1InProzent: string;
        skontoInEuro: string;
        faelligkeitMitSkonto: string;
        faelligkeitOhneSkonto: string;
        baustellenEintrag: ZugewieseneBaustelleUpdated[];
      },
      formikHelpers: FormikHelpers<{
        belegtyp: string;
        rechnungsnummer: string;
        rechnungsdatum: string;
        rechnungseingang: string;
        bruttobetrag: string;
        rechnungsBetrag: string;
        rechnungsbetragAbweichend: boolean;
        nettobetrag: string;
        umsatzsteuer: string;
        lieferant: any;
        kundennummer: string;
        steuer: number;
        steuernummer: string;
        umsatzsteuerId: string;
        pruefStatus: string;
        iban: string;
        geschaefspartnerKonto: string;
        skonto1InProzent: string;
        skontoInEuro: string;
        faelligkeitMitSkonto: string;
        faelligkeitOhneSkonto: string;
        baustellenEintrag: ZugewieseneBaustelleUpdated[];
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const rechnung = await mutateAsync({
        belegTyp: values.belegtyp,
        rechnungsNummer: values.rechnungsnummer,
        rechnungsDatum: values.rechnungsdatum
          ? values.rechnungsdatum + "T00:00:00Z"
          : values.rechnungsdatum,
        bruttoBetrag: parseFloat(
          values.bruttobetrag.replace(".", "").replace(",", ".")
        ),
        rechnungsBetrag: parseFloat(
          values.rechnungsBetrag.replace(".", "").replace(",", ".")
        ),
        rechnungsbetragAbweichend: values.rechnungsbetragAbweichend,
        nettoBetrag: parseFloat(
          values.nettobetrag.replace(".", "").replace(",", ".")
        ),
        lieferantId:
          typeof values.lieferant === "string"
            ? values.lieferant
            : (values.lieferant as Lieferant)?.id ?? "",
        kundenNummer: values.kundennummer,
        steuer: values.steuer,
        steuernummer: values.steuernummer,
        umsatzsteuerId: values.umsatzsteuerId,
        iban: values.iban,
        geschaeftspartnerKonto: values.geschaefspartnerKonto,
        skontoProzent: parseFloat(
          values.skonto1InProzent.replace(".", "").replace(",", ".")
        ),
        skontierfaehigerBetrag: parseFloat(
          values.skontoInEuro.replace(".", "").replace(",", ".")
        ),
        skontoDatum: values.faelligkeitMitSkonto
          ? values.faelligkeitMitSkonto + "T00:00:00Z"
          : values.faelligkeitMitSkonto,
        faelligOhneSkonto: values.faelligkeitOhneSkonto
          ? values.faelligkeitOhneSkonto + "T00:00:00Z"
          : values.faelligkeitOhneSkonto,
      });

      await updateBaustellenEintraege(values.baustellenEintrag);
      navigate("/eingangsrechnungen");
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );

  async function updateBaustellenEintraege(
    eintraege: ZugewieseneBaustelleUpdated[]
  ) {
    const baustellenPromises = eintraege.map((baustelle) => {
      const formattedBetrag = parseFloat(
        String(baustelle.betrag).replace(".", "").replace(",", ".")
      );
      const prueferId = (baustelle.pruefer as Benutzer)?.id ?? null;
      const baustellenId = (baustelle.baustelle as Baustelle)?.id ?? null;
      const baustelleData = {
        baustellenId: baustellenId,
        prueferId: prueferId,
        betrag: formattedBetrag,
        pruefStatus: baustelle.pruefStatus,
        leistung: baustelle.leistung,
      };
      if (baustelle.id) {
        return updateBaustellenEintrag(rechnungId, baustelle.id, baustelleData);
      } else {
        return createBaustellenEintrag(rechnungId, baustelleData);
      }
    });
    await Promise.all(baustellenPromises);
  }

  const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
  // @ts-ignore
  return (
    <Layout title={t("audit")} back={"/eingangsrechnungen"}>
      <Guard
        permission={"eingangsrechnung:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Box
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3, height: "90vh" }}>
                <Box
                  sx={{
                    marginTop: 2,
                    height: "calc(100% - 48px)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Stack direction={"row"}>
                    <Typography variant="h5" gutterBottom>
                      {rechnung.geloeschtAm === undefined ? (
                        t("preview")
                      ) : (
                        <>
                          <>{t("preview")}</>
                          <Chip
                            size="small"
                            icon={<DeleteIcon />}
                            label={t("deleted")}
                            color="error"
                          />
                        </>
                      )}
                    </Typography>
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <ZoomOut>
                        {(props: RenderZoomOutProps) => (
                          <button
                            style={{
                              backgroundColor: "#c7333d",
                              border: "none",
                              borderRadius: "4px",
                              color: "#ffffff",
                              cursor: "pointer",
                              padding: "8px",
                            }}
                            onClick={props.onClick}
                          >
                            Zoom out
                          </button>
                        )}
                      </ZoomOut>
                      <Box sx={{ ml: 1, mr: 1 }}>
                        <CurrentScale>
                          {(props: RenderCurrentScaleProps) => (
                            <>{`${Math.round(props.scale * 100)}%`}</>
                          )}
                        </CurrentScale>
                      </Box>

                      <ZoomIn>
                        {(props: RenderZoomInProps) => (
                          <button
                            style={{
                              backgroundColor: "#c7333d",
                              border: "none",
                              borderRadius: "4px",
                              color: "#ffffff",
                              cursor: "pointer",
                              padding: "8px",
                            }}
                            onClick={props.onClick}
                          >
                            Zoom in
                          </button>
                        )}
                      </ZoomIn>
                    </Stack>
                  </Stack>

                  <Box sx={{ flex: 1, overflow: "auto" }}>
                    {fileUrl ? (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={fileUrl}
                          plugins={[zoomPluginInstance]}
                        />
                      </Worker>
                    ) : (
                      <Typography variant="body2" gutterBottom>
                        {t("no-pdf")}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  belegtyp: rechnung.belegTyp ?? "",
                  rechnungsnummer: rechnung.rechnungsNummer ?? "",
                  rechnungsdatum: rechnung.rechnungsDatum?.split("T")[0] ?? "",
                  rechnungseingang: rechnung.timestamp?.split("T")[0] ?? "",
                  bruttobetrag: formatNumber(rechnung.bruttoBetrag) ?? "",
                  rechnungsBetrag: formatNumber(rechnung.rechnungsBetrag) ?? "",
                  rechnungsbetragAbweichend:
                    rechnung.rechnungsbetragAbweichend ?? false,
                  nettobetrag: formatNumber(rechnung.nettoBetrag) ?? "",
                  lieferant: rechnung.lieferantId ?? "",
                  umsatzsteuer: formatNumber(
                    (rechnung.bruttoBetrag ?? 0) - (rechnung.nettoBetrag ?? 0)
                  ),
                  steuer: rechnung.steuer ?? 19,
                  steuernummer: rechnung.steuernummer ?? "",
                  umsatzsteuerId: rechnung.umsatzsteuerId ?? "",
                  pruefStatus: rechnung.pruefStatus ?? "",
                  iban: rechnung.iban ?? "",
                  geschaefspartnerKonto: rechnung.geschaeftspartnerKonto ?? "",
                  kundennummer: rechnung.kundenNummer ?? "",
                  skonto1InProzent: formatNumber(rechnung.skonto1Prozent) ?? "",
                  skontoInEuro:
                    formatNumber(rechnung.skontierfaehigerBetrag) ?? "",
                  faelligkeitMitSkonto:
                    rechnung.skonto1Datum?.split("T")[0] ?? "",
                  faelligkeitOhneSkonto:
                    rechnung.faelligOhneSkonto?.split("T")[0] ?? "",
                  baustellenEintrag: baustellenEintraege?.length
                    ? baustellenEintraege.map((eintrag) => {
                        return {
                          id: eintrag.id,
                          rechnungsId: eintrag.rechnungsId,
                          baustelle: eintrag.baustelle,
                          pruefer: eintrag.pruefer,
                          betrag: eintrag.betrag,
                          pruefStatus: eintrag.pruefStatus,
                          leistung: eintrag.leistung,
                        };
                      })
                    : [],
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValidating, isValid, dirty, values }) => (
                  <>
                    <Form id="details-eingangsrechnung">
                      <RechnungsFormularFieldsContent
                        rechnungId={rechnungId}
                        rechnung={rechnung}
                        isSubmitting={isSubmitting}
                        isValidating={isValidating}
                        isValid={isValid}
                        dirty={dirty}
                        values={values}
                      />
                    </Form>
                  </>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Guard>
    </Layout>
  );
}

function RechnungsFormularFieldsContent({
  rechnungId,
  rechnung,
  isSubmitting,
  isValidating,
  isValid,
  dirty,
  values,
}: {
  rechnungId: string;
  rechnung: Rechnung;
  isSubmitting: boolean;
  isValidating: boolean;
  isValid: boolean;
  dirty: boolean;
  values: FormikValues;
}) {
  const { t } = useT("eingangsrechnung");
  const navigate = useNavigate();
  const createBaustellenEintrag = useAssignBaustelleZuRechnung();
  const updateBaustellenEintrag = useUpdateZugewieseneBaustelle();
  const { mutateAsync } = useSaveRechnung(rechnungId);
  const { setFieldValue } = useFormikContext();
  const [liefarentenField] = useField("lieferant");
  const [nettobetragField] = useField("nettobetrag");
  const [bruttobetragField] = useField("bruttobetrag");

  const handleNewLieferantClick = () => {
    const currentHost = window.location.origin;
    window.open(`${currentHost}/neu-lieferant`, "_blank");
  };

  useEffect(() => {
    if (
      rechnung.geschaeftspartnerKonto === null ||
      rechnung.geschaeftspartnerKonto === undefined ||
      rechnung.geschaeftspartnerKonto === ""
    ) {
      if (typeof liefarentenField.value === "object") {
        setFieldValue(
          "geschaefspartnerKonto",
          (liefarentenField.value as Lieferant)?.lieferantennummer ?? ""
        );
      } else {
        setFieldValue("geschaefspartnerKonto", "");
      }
    }
  }, [liefarentenField.value]);

  useEffect(() => {
    const nettoBetrag =
      nettobetragField.value.replace(".", "").replace(",", ".") ?? 0;
    const bruttoBetrag =
      bruttobetragField.value.replace(".", "").replace(",", ".") ?? 0;

    const umsatzBetrag = (bruttoBetrag - nettoBetrag).toFixed(2);

    setFieldValue("umsatzsteuer", umsatzBetrag);
  }, [nettobetragField.value, bruttobetragField.value]);

  async function updateBeleg(values: any) {
    const rechnung = await mutateAsync({
      belegTyp: values.belegtyp ?? "Eingangsrechnung",
      rechnungsNummer: values.rechnungsnummer,
      rechnungsDatum: values.rechnungsdatum
        ? values.rechnungsdatum + "T00:00:00Z"
        : values.rechnungsdatum,
      bruttoBetrag: parseFloat(
        values.bruttobetrag.replace(".", "").replace(",", ".")
      ),
      rechnungsBetrag: parseFloat(
        values.rechnungsBetrag.replace(".", "").replace(",", ".")
      ),
      rechnungsbetragAbweichend: values.rechnungsbetragAbweichend,
      nettoBetrag: parseFloat(
        values.nettobetrag.replace(".", "").replace(",", ".")
      ),
      lieferantId:
        typeof values.lieferant === "string"
          ? values.lieferant
          : (values.lieferant as Lieferant)?.id ?? "",
      kundenNummer: values.kundennummer,
      steuer: values.steuer,
      steuernummer: values.steuernummer,
      umsatzsteuerId: values.umsatzsteuerId,
      iban: values.iban,
      geschaeftspartnerKonto: values.geschaefspartnerKonto,
      skontoProzent: parseFloat(values.skonto1InProzent.replace(",", ".")),
      skontierfaehigerBetrag: parseFloat(values.skontoInEuro.replace(",", ".")),
      skontoDatum: values.faelligkeitMitSkonto
        ? values.faelligkeitMitSkonto + "T00:00:00Z"
        : values.faelligkeitMitSkonto,
      faelligOhneSkonto: values.faelligkeitOhneSkonto
        ? values.faelligkeitOhneSkonto + "T00:00:00Z"
        : values.faelligkeitOhneSkonto,
    });
  }

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <DublettenCheck
        rechnungId={rechnungId}
        rechnungsnummer={values.rechnungsnummer}
        lieferantenId={
          typeof values.lieferant === "string"
            ? values.lieferant
            : (values.lieferant as Lieferant)?.id ?? ""
        }
      />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>{t("invoice")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
            }}
          >
            <Field
              name="belegtyp"
              component={Select}
              label={t("document-type") + " *"}
            >
              <MenuItem key="rechnungseingang" value="Eingangsrechnung">
                {t("invoice")}
              </MenuItem>
              <MenuItem key="Gutschrift" value="Gutschrift">
                {t("credit")}
              </MenuItem>
              <MenuItem key="Abbuchung" value="Abbuchung">
                {t("debit")}
              </MenuItem>
            </Field>
            <Field
              component={TextField}
              name="rechnungsnummer"
              label={t("invoice-number")}
            ></Field>
            <Field
              component={TextField}
              label={t("invoice-date")}
              name="rechnungsdatum"
              InputLabelProps={{ shrink: true }}
              type={"date"}
            />
            <Field
              component={TextField}
              label={t("invoice-entry")}
              name={"rechnungseingang"}
              type={"date"}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "grid",
              gap: 2,
              gridTemplateColumns: ["1fr", "2fr 1fr 1fr"],
              alignItems: "center",
            }}
          >
            <Field
              component={LieferantenSelect}
              name="lieferant"
              label="Lieferant"
            ></Field>
            <Button
              variant={"outlined"}
              sx={{ height: "70%" }}
              onClick={handleNewLieferantClick}
            >
              <AddIcon />
              {t("new-supplier")}
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "grid",
              gap: 2,
              gridTemplateColumns: ["1fr", "2fr 1fr 1fr "],
              alignItems: "center",
            }}
          >
            <Field component={TextField} name="iban" label={t("iban")}></Field>
            <Field
              component={TextField}
              name="steuernummer"
              label={t("tax-number")}
            ></Field>
            <Field
              component={TextField}
              name="umsatzsteuerId"
              label={t("sales-tax-id")}
            ></Field>
            <Field
              component={TextField}
              name="kundennummer"
              label={t("supplier-customer-number")}
            ></Field>
            <Field
              component={TextField}
              name="geschaefspartnerKonto"
              label={t("business-partner-account")}
            ></Field>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>{t("invoice-amount")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
            }}
          >
            <Field
              component={TextField}
              name="nettobetrag"
              label={t("net-amount-euro")}
              inputProps={{ style: { textAlign: "right" } }}
            ></Field>
            <Field
              component={TextField}
              name="steuer"
              label={t("tax-rate")}
              inputProps={{ style: { textAlign: "right" } }}
            ></Field>
            <Field
              component={TextField}
              name="umsatzsteuer"
              label={t("sales-tax")}
              inputProps={{
                style: { textAlign: "right" },
              }}
              disabled={true}
            ></Field>
            <Field
              component={TextField}
              name="bruttobetrag"
              label={t("gross-amount-euro")}
              inputProps={{ style: { textAlign: "right" } }}
            ></Field>
            <FormControlLabel
              sx={{
                gridColumn: { sm: "1 / 3" },
              }}
              control={
                <Field
                  name="rechnungsbetragAbweichend"
                  as={Switch}
                  color="primary"
                  type="checkbox"
                  onClick={(e: any) => {
                    if (values.rechnungsbetragAbweichend === true) {
                      const brutto = isNaN(
                        parseFloat(
                          values.bruttobetrag.replace(".", "").replace(",", ".")
                        )
                      )
                        ? 0
                        : parseFloat(
                            values.bruttobetrag
                              .replace(".", "")
                              .replace(",", ".")
                          );
                      const skontierFaehigerBetrag = isNaN(
                        parseFloat(
                          values.skontoInEuro.replace(".", "").replace(",", ".")
                        )
                      )
                        ? 0
                        : parseFloat(
                            values.skontoInEuro
                              .replace(".", "")
                              .replace(",", ".")
                          );
                      const skontoProzent = isNaN(
                        parseFloat(
                          values.skonto1InProzent
                            .replace(".", "")
                            .replace(",", ".")
                        )
                      )
                        ? 0
                        : parseFloat(
                            values.skonto1InProzent
                              .replace(".", "")
                              .replace(",", ".")
                          );

                      const aktuellesDatum = new Date();
                      const faelligkeitMitSkontoDatum = new Date(
                        values.faelligkeitMitSkonto
                      );

                      if (faelligkeitMitSkontoDatum < aktuellesDatum) {
                        values.rechnungsBetrag = Math.fround(
                          brutto -
                            (skontierFaehigerBetrag * skontoProzent) / 100
                        )
                          .toFixed(2)
                          .toString();
                      } else {
                        values.rechnungsBetrag = Math.fround(brutto)
                          .toFixed(2)
                          .toString();
                      }
                    }
                  }}
                />
              }
              label={capitalize(t("invoice-amount-deviant"))}
            />
            <Field
              component={TextField}
              name="rechnungsBetrag"
              label={capitalize(t("invoice-amount-euro"))}
              type={"string"}
              sx={{
                gridColumn: { sm: "3 / 5" },
              }}
              disabled={!values.rechnungsbetragAbweichend}
            ></Field>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>{t("construction-site-assignment")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BaustellenEintrag
            baustellenEintraege={values.baustellenEintrag}
            rechnungId={rechnungId}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Rabatte</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: ["1fr", ".6fr 1.25fr 1fr 1fr"],
            }}
          >
            <SkontoFields bruttoBetrag={values.bruttobetrag} />
            <Field
              component={TextField}
              name="faelligkeitMitSkonto"
              label={"Fälligkeit mit Skonto"}
              type={"date"}
              InputLabelProps={{ shrink: true }}
            ></Field>
            <Field
              component={TextField}
              name="faelligkeitOhneSkonto"
              label={"Fälligkeit ohne Skonto"}
              type={"date"}
              InputLabelProps={{ shrink: true }}
            ></Field>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Aligns button to the right
          mt: 2, // Adds some margin to the top to separate it from the fields above
        }}
      >
        <DeleteRechnungenButton id={rechnungId} />
        <SubmitButton
          sx={{ marginTop: 3, ml: 2 }}
          form="details-eingangsrechnung"
          type="submit"
          variant="outlined"
          color="secondary"
          loading={isSubmitting}
          disabled={isValidating || !isValid || !dirty}
        >
          {t("save")}
        </SubmitButton>
        {values.pruefStatus === "OFFEN" ? (
          <Button
            sx={{ marginTop: 3, ml: 2 }}
            variant="contained"
            color="secondary"
            onClick={async () => {
              if (dirty) {
                await updateBeleg(values);
              }
              for (const baustelle of values.baustellenEintrag) {
                if (
                  baustelle.pruefStatus === "offen" ||
                  baustelle.pruefStatus === "in Prüfung"
                ) {
                  const prueferId = (baustelle.pruefer as Benutzer)?.id ?? null;
                  const baustellenId =
                    (baustelle.baustelle as Baustelle)?.id ?? null;
                  if (baustelle.id) {
                    baustelle.pruefStatus = "in Prüfung";
                    const formattedBetrag = parseFloat(
                      String(baustelle.betrag)
                        .replace(".", "")
                        .replace(",", ".")
                    );
                    updateBaustellenEintrag(rechnungId, baustelle.id, {
                      baustellenId: baustellenId,
                      prueferId: prueferId,
                      leistung: baustelle.leistung,
                      betrag: formattedBetrag,
                      pruefStatus: "in Prüfung",
                    });
                  } else {
                    const erstellt = await createBaustellenEintrag(rechnungId, {
                      baustellenId: baustellenId,
                      prueferId: prueferId,
                      leistung: baustelle.leistung,
                      betrag: parseFloat(
                        String(baustelle.betrag)
                          .replace(".", "")
                          .replace(",", ".")
                      ),
                      pruefStatus: "in Prüfung",
                    });
                    baustelle.id = erstellt.id;
                  }
                }
              }
              navigate("/eingangsrechnungen");
            }}
          >
            {t("assign")}
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Paper>
  );
}

function useSaveRechnung(rechnungId: string) {
  const updateRechnung = useUpdateRechnung();
  const queryClient = useQueryClient();
  return useMutation(
    (input: RechnungDaten) => {
      return updateRechnung(rechnungId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["rechnungen"]);
      },
    }
  );
}

function useGetAnhang(
  rechnungId: string,
  options: { enabled?: boolean } = {}
): UseQueryResult<Blob> {
  const getAccessToken = useGetAccessToken();
  return useQuery<Blob>(
    ["attachments", rechnungId],
    async () => {
      const response = await fetch(
        `${apiUrl}/rechnungen/${rechnungId}/anhang`,
        { headers: { authorization: `Bearer ${await getAccessToken()}` } }
      );
      const blob = await response.blob();
      return blob;
    },
    { ...options, suspense: false, cacheTime: 0 }
  );
}

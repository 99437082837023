/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Identifiable } from '../models/Identifiable';
import type { Material } from '../models/Material';
import type { MaterialDaten } from '../models/MaterialDaten';
import type { MaterialKosten } from '../models/MaterialKosten';
import type { MaterialKostenCreate } from '../models/MaterialKostenCreate';
import type { MaterialKostenPage } from '../models/MaterialKostenPage';
import type { MaterialKostenUpdate } from '../models/MaterialKostenUpdate';
import type { MaterialKostenZeitraum } from '../models/MaterialKostenZeitraum';
import type { MaterialKostenZeitraumPrice } from '../models/MaterialKostenZeitraumPrice';
import type { MaterialListe } from '../models/MaterialListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MaterialService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Materialien.
   * @returns MaterialListe Die Seite der Materialien.
   * @throws ApiError
   */
  public sucheMaterial({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaterialListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/material',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Material Das angelegte Material
   * @throws ApiError
   */
  public createMaterial({
    requestBody,
  }: {
    requestBody: MaterialDaten,
  }): CancelablePromise<Material> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/material',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get Material by ID
   * Retrieves the details of a Material by its ID
   * @returns Material Das Material
   * @throws ApiError
   */
  public getMaterial({
    id,
  }: {
    id: string,
  }): CancelablePromise<Material> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/material/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update Material by ID
   * Updates an existing Material by its ID
   * @returns Material Das aktualisierte Material
   * @throws ApiError
   */
  public updateMaterial({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: MaterialDaten,
  }): CancelablePromise<Material> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/material/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete Material by ID
   * Deletes an existing Material by its ID
   * @returns Material Das gelöschte Material
   * @throws ApiError
   */
  public deleteMaterial({
    id,
  }: {
    id: string,
  }): CancelablePromise<Material> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/material/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get Material costs by ID
   * Retrieves the costs of a Material by its ID
   * @returns MaterialKostenPage Die Kosten des Materials
   * @throws ApiError
   */
  public getMaterialCosts({
    materialId,
    search,
    page,
    limit,
    sort,
  }: {
    materialId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaterialKostenPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/material/{materialId}/costs',
      path: {
        'materialId': materialId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update Material costs by ID
   * Updates the costs of a Material by its ID
   * @returns MaterialKosten Die aktualisierten Kosten des Materials
   * @throws ApiError
   */
  public updateMaterialZeitraum({
    materialId,
    requestBody,
  }: {
    materialId: string,
    requestBody: MaterialKostenZeitraum,
  }): CancelablePromise<MaterialKosten> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/material/{materialId}/costs',
      path: {
        'materialId': materialId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns MaterialKosten Das angelegte Material
   * @throws ApiError
   */
  public createMaterialCost({
    materialId,
    requestBody,
  }: {
    materialId: string,
    requestBody: MaterialKostenCreate,
  }): CancelablePromise<MaterialKosten> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/material/{materialId}/costs',
      path: {
        'materialId': materialId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Identifiable Get Materialcost Id
   * @throws ApiError
   */
  public getMaterialCostId({
    materialId,
  }: {
    materialId: string,
  }): CancelablePromise<Identifiable> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/material/{materialId}/costs/id',
      path: {
        'materialId': materialId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update Material costs by ID
   * Updates the costs of a Material by its ID
   * @returns MaterialKosten Die aktualisierten Kosten des Materials
   * @throws ApiError
   */
  public updateMaterialPrice({
    materialId,
    requestBody,
  }: {
    materialId: string,
    requestBody: MaterialKostenZeitraumPrice,
  }): CancelablePromise<MaterialKosten> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/material/{materialId}/costs/price',
      path: {
        'materialId': materialId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update Material costs by ID
   * Updates the costs of a Material by its ID
   * @returns MaterialKosten Die aktualisierten Kosten des Materials
   * @throws ApiError
   */
  public updateMaterialKosten({
    materialId,
    requestBody,
  }: {
    materialId: string,
    requestBody: MaterialKostenUpdate,
  }): CancelablePromise<MaterialKosten> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/material/{materialId}/costs/update',
      path: {
        'materialId': materialId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get materials by Abladestelle ID
   * Retrieves materials for a specific Abladestelle based on its ID
   * @returns MaterialListe A list of materials
   * @throws ApiError
   */
  public fetchMaterialsByAbladestelleId({
    abladestelleId,
  }: {
    abladestelleId: string,
  }): CancelablePromise<MaterialListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/materialien/abladestelle/{abladestelleId}',
      path: {
        'abladestelleId': abladestelleId,
      },
      errors: {
        404: `Abladestelle not found`,
      },
    });
  }

}
